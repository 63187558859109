import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useEffect, useState } from "react";
import AppleSignin from "react-apple-signin-auth";
import { connect } from "react-redux";
import AppelIcon from "../../assets/auth-icons/appel-Icon.svg";
import GoogleIcon from "../../assets/auth-icons/google-icon.svg";
import InputPhoneEmail from "../../components/Auth/InputPhoneEmail/InputPhoneEmail";
import { NavAuth } from "../../components/Auth/NavAuth/NavAuth";
import withNavigateHook from "../../hooks/withNavigateHook";
import { languageData } from "../../i18n/index";
import {
  clearStoreItem,
  logout,
  sendVerificationCode,
  updateLoading,
  updateUserAuth,
  verifyApple,
  verifyGoogle,
} from "../../store/actions/actions";
import { validation } from "../../utils/Validation";
import "./AuthStyles.scss";
import { navigateBasedOnRestaurantCount } from "../../utils/navigationUtils";
import { useConfig } from "../../context/ConfigContext";

interface phoneEmailInterface {
  filedInput: string;
  inputType: string | "email" | "tel";
  countryPrefix: string;
}

type phoneEmailDispatcherInterface = (
  field: string,
  newValue: string | "tel" | "email"
) => void;

function AuthentificationPage(props: any) {
  const { config } = useConfig();
  const {
    establishementCount: restaurantCount = 0,
    isGlobalWebsite = false,
  } = config || {};

  const [phoneEmailState, setPhoneEmailState] = useState<phoneEmailInterface>({
    filedInput: "",
    inputType: "email",
    countryPrefix: "",
  });
  const [loggedWithThirdParty, setLoggedWithThirdParty] =
    useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [retries, setretries] = useState(0);
  const [hasErrorInput, sethasErrorInput] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [hasErrorServer, sethasErrorServer] = useState("");
  let __forceUserToEmail =
    props.location?.state?.forUser === "email" && props.user.length !== 0;
  let __forceUserToPhone =
    props.location?.state?.forUser === "phone" && props.user.length !== 0;
  const redirectedToAuth = localStorage.getItem("redirectedToAuth") === "true";

  const ValidateInput = () => {
    const phoneNumber = 
      "+" + phoneEmailState.countryPrefix + phoneEmailState.filedInput
    
    const isMailValid = validation.ValidateEmail(phoneEmailState.filedInput);
    const isPhone =
      phoneNumber &&
      validation.ValidatePhonenumber(phoneEmailState.filedInput);

    return {
      isMailValid,
      isPhone,
    };
  };

  const Tokent = localStorage.getItem("accessToken");

  useEffect(() => {
    if (loggedWithThirdParty && redirectedToAuth) {
      props.navigate("/paiement");
      return;
    }
    if (Tokent) {
      navigateBasedOnRestaurantCount(restaurantCount,isGlobalWebsite,props.navigate)
    }
  }, [Tokent]);

  const sendVerifCode = () => {
    const validation = ValidateInput();
    const isPhone = validation.isPhone;
    let purePhone =
      "+" +
      phoneEmailState.countryPrefix +
      phoneEmailState.filedInput?.replace(" ", "");

    props.sendVerificationCode(
      {
        input: isPhone ? purePhone : phoneEmailState.filedInput,
        hashCode: "0000",
        preferredMethod: validation.isMailValid ? 0 : 1,
        mode: props.userMode,
      },
      false
    );
  };

  const handleSubmit = () => {
    setHasError(false);
    sethasErrorInput(false);
    sethasErrorServer("");

    const validation = ValidateInput();

    const purePhone =
      "+" +
      phoneEmailState.countryPrefix +
      phoneEmailState.filedInput?.replace(" ", "");
    if (loggedWithThirdParty) {
      if (!validation.isMailValid && !validation.isPhone) {
        setHasError(true);
        return; // Exit the function early if neither email nor phone is valid
      }

      props.sendVerificationCode(
        {
          input: purePhone,
          hashCode: "0000",
          preferredMethod: 1,
          mode: 2,
        },
        false
      );
      const userInfo = validation.isMailValid
        ? { email: phoneEmailState.filedInput, type: "email" }
        : { phone: purePhone, type: "phone" };

      props.updateUserInfo({ ...userInfo });
    } else {

      if (phoneEmailState.filedInput.trim() === "") {
        sethasErrorInput(true);
        setHasError(true);
        seterrorMessage("Le champ ne peut pas être vide");
        return; // Exit the function early if the input is empty
      }

      if (!validation.isMailValid && !validation.isPhone) {
        if (/[a-zA-Z]/.test(phoneEmailState.filedInput) || __forceUserToEmail) {
          setHasError(true);
          seterrorMessage("E-mail non valide");
          return;
        } else if (!validation.isPhone || __forceUserToPhone) {
          setHasError(true);
          seterrorMessage("Numéro non valide");
          return;
        }else{
          setHasError(true);
          seterrorMessage("E-mail ou numéro de téléphone est non valide");
          return;
        }
      }

      if (!validation.isMailValid && __forceUserToEmail) {
        sethasErrorInput(true);
        setHasError(true);
        seterrorMessage("Invalid email");
        return; // Exit the function early if email is invalid and should be forced
      }

      if (!validation.isPhone && __forceUserToPhone) {
        sethasErrorInput(true);
        setHasError(true);
        seterrorMessage("Invalid phone");
        return; // Exit the function early if phone is invalid and should be forced
      }

      // At this point, either email or phone is valid, proceed to send verification code
      const userInfo = validation.isMailValid
        ? { email: phoneEmailState.filedInput, type: "email" }
        : { phone: purePhone, type: "phone" };
      sendVerifCode();
      props.updateUserInfo({ ...userInfo });
      setretries(retries + 1);
      if (
        phoneEmailState.filedInput &&
        phoneEmailState.filedInput.length > 245
      ) {
        sethasErrorInput(true);
        sethasErrorServer(
          languageData.fr.auth["login.error.input.email.length"]
        );

        return; // Exit the function early if email length exceeds 245 characters
      }
    }
  };

  useEffect(() => {
    if (props.sendVerificationCodeResponse) {
      if (phoneEmailState.filedInput) {
        const phoneNumber ="+" + phoneEmailState.countryPrefix + phoneEmailState.filedInput

        let object = {
          input: phoneNumber
            ? phoneNumber
            : phoneEmailState.filedInput,
          hashCode: "0000",
          prefferedMethod: validation.ValidateEmail(phoneEmailState.filedInput)
            ? 0
            : 1,
          mode: props.userMode,
        };
        props.clearItemStore("sendVerificationCodeResponse");
        props.clearItemStore("resendVerificationCodeResponse");
        props.clearItemStore("authError");
        props.userMode == 1
          ? props.navigate("passwordPage", { replace: true, state: object })
          : props.navigate("otp", { replace: true, state: object });
      } else if (props.sendVerificationCodeResponse) {
        const phoneNumber = parsePhoneNumberFromString(
          "+" + phoneEmailState.countryPrefix + phoneEmailState.filedInput
        );
        console.log("phone number", phoneNumber);
        let object = {
          input: phoneNumber?.isValid()
            ? phoneNumber?.number
            : phoneEmailState.filedInput,
          hashCode: "0000",
          preferredMethod: 0,
          mode: props.userMode,
        };
        props.clearItemStore("sendVerificationCodeResponse");
        props.clearItemStore("resendVerificationCodeResponse");
        props.clearItemStore("authError");
        props.navigate("otp", { replace: true, sendVerifCodeObject: object });
      }
    }
  });

  useEffect(() => {
    if (props.authError && !props.sendVerificationCodeResponse) {
      // const phoneNumber = parsePhoneNumber("+" + phoneCode + userInput)

      if (props.authError == "email incorrect") {
        sethasErrorInput(true);
        sethasErrorServer(languageData.fr.serverError["email.exist"]);
      } else if (props.authError == "email existant") {
        sethasErrorInput(true);
        sethasErrorServer(languageData.fr.serverError["email.exist"]);
      } else if (props.authError == "phonennumber existe") {
        sethasErrorInput(true);
        sethasErrorServer(languageData.fr.serverError["phone.exist"]);
      }
    }
  }, [props.authError, props.authRetries]);

  useEffect(() => {
    if (props?.authGoogleSuccess) {
      let googleResponse: serviceAuthModels.GoogleVerifyTokenResponse =
        props?.authGoogleSuccess;
      props.updateUserInfo({
        email: googleResponse?.payloadWeb?.email,
        type: "email",
        firstName: googleResponse?.payloadWeb?.given_name,
        lastName: googleResponse?.payloadWeb?.family_name,
      });
    }
  }, [props?.authGoogleSuccess]);

  useEffect(() => {
    if (props?.authAppleSuccess) {
      let AppleResponse = props.authAppleSuccess;

      props.updateUserInfo({
        email: AppleResponse?.payload?.email,
        type: "email",
      });
    }
  }, [props?.authAppleSuccess]);

  const googleLogin: any = useGoogleLogin({
    overrideScope: true,
    scope: "openid profile email", // Include openid scope to get id_token
    onSuccess: (tokenResponse: TokenResponse) => {
      console.log(tokenResponse);
      setLoggedWithThirdParty(true);
      props.verifyGoogle(`${tokenResponse.access_token}`);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  const setPhoneEmailFieldValue: phoneEmailDispatcherInterface = (
    field: string,
    newValue: string
  ) => {
    let newState: phoneEmailInterface = { ...phoneEmailState };

    newState[field as keyof phoneEmailInterface] = newValue;

    setPhoneEmailState((prevState) => {
      let newState: phoneEmailInterface = { ...prevState };

      newState[field as keyof phoneEmailInterface] = newValue;

      return newState;
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="authPage">
      <NavAuth />
      <div className="content">
        <div className="form-header">
          <p className="tite-form">Inscription ou connexion</p>

          {__forceUserToEmail && (
            <p className="sub-title">
              <small>Veuillez saisir votre e-mail </small>
            </p>
          )}

          {__forceUserToPhone ||
          props.authGoogleSuccess !== null ||
          loggedWithThirdParty ? (
            <p className="sub-title">
              Veuillez saisir votre numéro de téléphone
            </p>
          ) : null}

          {!__forceUserToEmail &&
            !__forceUserToPhone &&
            props.authGoogleSuccess === null &&
            !loggedWithThirdParty && (
              <p className="sub-title">
                Veuillez saisir votre numéro de téléphone
                <br /> ou votre e-mail
              </p>
            )}
        </div>

        <div className="form-body">
          <InputPhoneEmail
            loggedWithThirdParty={loggedWithThirdParty}
            hasErrorServer={hasErrorServer}
            forceToPhone={__forceUserToPhone}
            forceToEmail={__forceUserToEmail}
            forceToGoogle={props.authGoogleSuccess !== null}
            state={[phoneEmailState, setPhoneEmailFieldValue]}
            setError={(bool: boolean) => setHasError(bool)}
            hasError={hasError}
            handleKeyDown={handleKeyDown}
          />
          {props.authError !== "phonennumber existe" && (
            <p style={{ color: "red" }}>
              {(hasError || hasErrorInput) && errorMessage}
            </p>
          )}
          {/* {props.authError ? props.authError : ""} */}
          {props.authError === "phonennumber existe" ? (
            <div style={{ color: "red" }}>
              Ce numéro de téléphone est déjà associé à une autre adresse e-mail
            </div>
          ) : (
            ""
          )}
          <button onClick={() => handleSubmit()} className="btn-form continue">
            Continuer
          </button>

          {props.user?.type === "phone" || props.user?.type === "email" ? (
            ""
          ) : (
            <div>
              {(!__forceUserToEmail || !__forceUserToPhone) && (
                <div>
                  <div className="diver">{/*  <p>Ou</p> */}</div>
                  {props.authGoogleSuccess || props.authAppleSuccess ? (
                    ""
                  ) : (
                    <div className="social-media-wrapper">
                      <AppleSignin
                        /** Auth options passed to AppleID.auth.init() */
                        authOptions={{
                          /** Client ID - eg: 'com.example.com' */
                          clientId: "fioeat.frenchinnov.fr",
                          /** Requested scopes, seperated by spaces - eg: 'email name' */
                          scope: "email",
                          /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                          redirectURI: "https://fioeat.com",
                          /** State string that is returned with the apple response */
                          state: "state",
                          /** Nonce */
                          nonce: "nonce",
                          /** Uses popup auth instead of redirection */
                          usePopup: true,
                        }} // REQUIRED
                        /** General props */
                        uiType="dark"
                        /** className */
                        className="apple-auth-btn"
                        /** Removes default style tag */
                        noDefaultStyle={false}
                        /** Allows to change the button's children, eg: for changing the button text */
                        buttonExtraChildren="Continue with Apple"
                        /** Extra controlling props */
                        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                        onSuccess={(response: any) => {
                          let tt = response?.authorization?.id_token;
                          setLoggedWithThirdParty(true);

                          // props.verifyApple(`${response?.authorization?.id_token}`);
                          // props.verifyApple(`${response?.authorization?.id_token}`);
                          props.verifyApple(`${tt}`);
                        }} // default = undefined
                        /** Called upon signin error */
                        onError={(error: any) => console.error(error)} // default = undefined
                        /** Skips loading the apple script if true */
                        skipScript={false} // default = undefined
                        /** Apple image props */

                        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                        render={(props: any) => (
                          <button {...props} className="btn-form social-btn ">
                            <img src={AppelIcon} alt="" />
                            Continuer avec Apple
                          </button>
                        )}
                      />

                      <button
                        className="btn-form social-btn "
                        onClick={() => googleLogin()}
                      >
                        <img src={GoogleIcon} alt="" />
                        Continuer avec Google
                      </button>

                      {/* <button className="btn-form social-btn ">
                  <img src={FbIcon} alt="" />
                  Continuer avec Facebook
                </button> */}
                    </div>
                  )}

                  {/* <div className="popup-dev">
                    <p>En cours de developement</p>
                </div> */}
                </div>
              )}
            </div>
          )}
          <div className="content-description">
            <p>
              En continuant, vous acceptez de recevoir au numéro fourni des
              <b> appels</b> ou <b>SMS</b> de la part de <b>Frenchinnov</b> et/
              ou des sociétés Partenaire/ affiliées.
            </p>
            <p>
              En continuant, vous <b>acceptez nos conditions générales </b>
              et vous confirmez avoir lu et compris notre politique de
              confidentialité. Nous utilisons vos
              <b> données personnelles</b> pour vous offrir une expérience
              personnalisée, ainsi que pour mieux comprendre et amériorer notre
              service. Pour plus de détails,{" "}
              <b onClick={() => props.navigate("/apropos")}>cliquez ici.</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    authGoogleSuccess: state.auth.authGoogleSuccess,
    authAppleSuccess: state.auth.authAppleSuccess,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    authRetries: state.auth.authRetries,
    userMode: state.auth.userMode,
    authError: state.auth.authError,
    verifyCodeSuccess: state.auth.verifyCodeSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    verifyGoogle: (token: string) => dispatch(verifyGoogle(token)),
    verifyApple: (token: string) => dispatch(verifyApple(token)),
    updateUserInfo: (userInfo: {
      email: string;
      type: string;
      phone: string;
    }) => dispatch(updateUserAuth(userInfo)),
    updateLoading: (value: boolean) => dispatch(updateLoading(value)),
    sendVerificationCode: (
      body: serviceAuthModels.SendVerificationCodeBody,
      resend: boolean
    ) => dispatch(sendVerificationCode(body, resend)),
    logout: () => dispatch(logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(AuthentificationPage));
