import { Category, CATEGORY_ITEM_TYPE, CategoryItem, GroupedArticleByLevel, MenuItem } from "../interfaces/Catalogue";

export const filterCategoryItemsByOrderType = (CategoryItems: CategoryItem[], orderType: number): CategoryItem[] => {
    const filteredItems = CategoryItems.filter(categoryItem =>
        categoryItem.typeOrderCategory.some(category => category.type === orderType)
    );

    return filteredItems;
}

export const groupMenuItemsByLevel = (
    menuItems: MenuItem[],
    typeOrder: number
): GroupedArticleByLevel[] => {
    const levelMap: { [levelId: number]: GroupedArticleByLevel } = {};

    // Iterate over the menu items to group them by `levelId`.
    menuItems.forEach(item => {
        const { levelId, level } = item;

        if (!levelMap[levelId]) {
            levelMap[levelId] = { ...level, menuItems: [] };
        }

        if (!item.isNotImportant) {
            levelMap[levelId].menuItems!.push(item);
            const existingTypeOrderIds = levelMap[levelId].typeOrderLevel!.map(item => item.typeOrderId);

            item.level.typeOrderLevel.forEach(typeOrder => {
                if (!existingTypeOrderIds.includes(typeOrder.typeOrderId)) {
                    levelMap[levelId].typeOrderLevel!.push(typeOrder);
                }
            });
        }
    });

    // Convert the `levelMap` into an array and sort groups by `orderIndex`.
    const sortedItems = Object.values(levelMap).sort((a, b) => a.orderIndex - b.orderIndex);

    // Sort menu items within each group by their `orderIndex`.
    sortedItems.forEach(level => {
        level?.menuItems?.sort((a, b) => a.orderIndex - b.orderIndex);
    });

    // Filter sorted groups based on `typeOrder` in their `typeOrderLevel` array.
    const ObjectSortedItems = Object.values(sortedItems)?.filter((level) =>
        level?.typeOrderLevel?.some((item) => item?.type === typeOrder)
    );

    const filteredAndMappedItems = ObjectSortedItems.map(level => {
        // Filter menu items based on `typeOrder` in their `typeOrderPrices` array.
        level.menuItems = level?.menuItems?.filter(menuItem =>
            menuItem.typeOrderPrices.some(price => price.type === typeOrder)
        );
        return level;
    });

    return filteredAndMappedItems.filter(level => (level?.menuItems?.length || 0) > 0);
};

export const filterCategoriesByTypeOrder = (categories: Category[], typeOrder: number): Category[] => {
    return categories
        ?.filter((category) =>
            category.typeOrderFamily.some((family) => family.type === typeOrder)
            && (category.categoryArticlesCount > 0 || category.categoryMenusCount > 0)
        )
        ?.sort((a, b) => a.orderIndex - b.orderIndex);
};


export const getCategoryItemType = (type: string) => {
    return {
        isMenu: type === CATEGORY_ITEM_TYPE.MENU,
        isArticle: type === CATEGORY_ITEM_TYPE.ARTICLE,
    };
};