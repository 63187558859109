import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MenuRender } from "../../components/RestaurantMenuPage/MenuRender/MenuRender";
import AppNavbar from "../../components/shared/AppNavbar/AppNavbar";
import AddressSelectionModal from "../../components/shared/Modals/DeliveryModal/AddressSelectionModal";
import RestaurantErrorModal from "../../components/shared/Modals/RestaurantErrorModal/RestaurantErrorModal";
import ShiftInfo from "../../components/shared/ShiftInfo/ShiftInfo";
import { MODE_DELIVERY, MODE_ONSITE, MODE_TAKEAWAY, typeOrderTabs } from "../../constants/constants";
import { useConfig } from "../../context/ConfigContext";
import withNavigateHook from "../../hooks/withNavigateHook";
import { languageData } from "../../i18n";
import {
  Category,
  Restaurant,
  RestaurantCart,
} from "../../interfaces/Catalogue";
import { shopApi } from "../../services/apiGate";
import { catalogueApi } from "../../services/catalogueApi";
import { deliveryApi } from "../../services/deliveryApi";
import {
  addShopToFavorite,
  categoryShopsChangeShopMode,
  clearStoreItem,
  logout,
  selectCategory,
} from "../../store/actions/actions";
import { getCategoriesRequest } from "../../store/V2/categories/categoriesActions";
import { setDeliveryAddress } from "../../store/V2/delivery/deliveryActions";
import { filterCategoriesByTypeOrder } from "../../utils/menuUtils";
import { navigateBasedOnRestaurantCount } from "../../utils/navigationUtils";
import { findCurrentShift } from "../../utils/shopSubtittleFormat";
import "./RestaurantMenuPage.scss";

const RestaurantMenuPage = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const { config } = useConfig();
  const location = useLocation();
  const restaurantCart: RestaurantCart =
    props.savedCategories[Number(restaurantId)] || {};
  const { mode } = location.state || {};
  const {
    establishementCount: restaurantCount = 0,
    isGlobalWebsite = false,
  } = config || {};

  const { categories } = useSelector((state: any) => state.categories);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [hasScrollListener, setHasScrollListener] = useState(true);
  const [typeOrder, setTypeOrder] = useState(mode || MODE_TAKEAWAY);
  const [loadingState, setLoadingState] = useState<any>(false);
  const [currentShifts, setCurrentShifts] = useState<serviceMenuModels.Shift>();
  const [RestorentFilter, setRestorentFilter] = useState("");
  const [restaurant, setRestaurant] = useState<Restaurant>();
  const [establishementToken, setEstablishementToken] = useState(
    localStorage.getItem("establishementToken")
  );
  const filteredCategories = filterCategoriesByTypeOrder(categories, typeOrder);

  useEffect(() => {
    const fetchRestaurant = async () => {
      setLoadingState(true);
      try {
        const res = await shopApi.getResturantById({
          id: Number(restaurantId),
          diningMode: typeOrder,
          adresse: {
            latitude: props.location?.lat,
            longitude: props.location?.lng,
          },
        });
        const azureUrl = res?.data?.restaurant?.azureUrl;

        const tokenRes = await catalogueApi.getEstablishementToken(azureUrl);
        const newToken = tokenRes.data.data.result;

        localStorage.setItem("establishementToken", newToken);
        setEstablishementToken(newToken);
        setRestaurant(res?.data?.restaurant);
        setCurrentShifts(findCurrentShift(res?.data?.restaurant));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    if (restaurantId) {
      fetchRestaurant();
    }
  }, [restaurantId, props.mode, props.location]);

  useEffect(() => {
    if (hasScrollListener) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (hasScrollListener) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasScrollListener]);

  useEffect(() => {
    if (restaurant?.azureUrl && establishementToken) {
      dispatch(getCategoriesRequest(restaurant?.azureUrl || ""));
    }
  }, [restaurant, establishementToken]);

  // useEffect(() => {
  //   if (typeOrder == 0 || typeOrder == 1) {
  //     props.changeCategoryShopMode(typeOrder);
  //   }
  // }, [typeOrder]);

  const scrollToSection = (index: number) => {
    setHasScrollListener(false);
    const sectionId = `menuSection${index}`;
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      window.scrollTo(0, sectionElement.offsetTop - 180);
      setActiveTabIndex(index);
    }

    setTimeout(() => {
      setHasScrollListener(true);
    }, 1000);
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll(".menuSection");
    let currentIndex = activeTabIndex;

    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      const offsetTop = window.innerHeight / 2;

      if (rect.top <= offsetTop && rect.bottom >= offsetTop) {
        currentIndex = index;
        setActiveTabIndex(currentIndex);
      }
    });
  };

  const filteredTabs = typeOrderTabs?.filter((tab) => {
    switch (tab.type) {
      case MODE_TAKEAWAY:
        return restaurant?.aEmporte || false;
      case MODE_ONSITE:
        return restaurant?.surPlace || false;
      case MODE_DELIVERY:
        return restaurant?.livraison || false;
      default:
        return false;
    }
  });

  const [showModal, setShowModal] = useState(
    mode == MODE_DELIVERY && restaurantCart.mode != MODE_DELIVERY
  );
  const [showRestaurantError, setShowRestaurantError] = useState(false);

  const handleShowErrorModal = () => {
    setShowRestaurantError(true);
  };

  const handleCloseErrorModal = () => {
    setShowRestaurantError(false);
  };

  const handlePressbtnModal = () => {
    setShowRestaurantError(false);
    setShowModal(true);
  };

  const checkRestaurantDelivery = async (
    address: google.maps.places.PlaceResult | undefined
  ) => {
    deliveryApi
      .checkRestaurantDelivery(String(restaurantId), address)
      .then((res) => {
        setTypeOrder(MODE_DELIVERY);
      })
      .catch((error) => {
        console.log("error", error);
        handleShowErrorModal();
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const handleConfirmAddress = (
    address: google.maps.places.PlaceResult | undefined
  ) => {
    // setShowModal(false);
    console.log("Confirmed Address: ", address);
    dispatch(setDeliveryAddress(address));
    checkRestaurantDelivery(address);
  };

  return (
    <div className="restaurant-details">
      {/* Establishment Navbar */}
      <AppNavbar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />

      {/* Establishment Grid */}
      <Grid className="restaurant-grid">
        <Grid className="listing-menus">
          {/* Establishment Header*/}
          <div className="restaurant-header">
            <div className="navigation">
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.clearItemStore("menu");
                  navigateBasedOnRestaurantCount(restaurantCount,isGlobalWebsite, navigate);
                }}
              />

              <div>
                <div className="restaurant-details">
                  <p className="restaurant-name">{restaurant?.nom}</p>
                  <p className="restaurant-time">
                    {loadingState ? (
                      <p>Loading...</p>
                    ) : (
                      <p>
                        <ShiftInfo currentShifts={currentShifts} />
                        {restaurant?.distance}Km • {restaurant?.tempsPrep} min{" "}
                        {restaurant?.specialites
                          ? "•" + restaurant?.specialites
                          : null}
                      </p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*  Categories Tabs*/}
          <Box
            sx={{
              backgroundColor: "white",
              position: "sticky",
              zIndex: 2,
              top: "125px",
              // "@media (max-width: 900px)": {
              //   // top: "80",
              //   // marginTop: "20px",
              //   backgroundColor:'red'
              // },
            }}
          >
            <Tabs
              className="tabContainer row MuiTabs-scrollableX tabContainer-menu-items"
              value={activeTabIndex}
              variant="scrollable"
              allowScrollButtonsMobile
              selectionFollowsFocus
              scrollButtons="auto"
              centered
              sx={{
                // display: {
                //   md: "none",
                // },
                // "& .MuiTabs-indicator": {
                //   backgroundColor: "#EE8113",
                // },
                "& .MuiTabs-scrollButtons": {
                  width: "12px",
                },
              }}
            >
              {filteredCategories?.map((category: any, index: number) => {
                return (
                  <Tab
                    className={`${index == activeTabIndex ? "current-active-tab" : ""}`}
                    key={index}
                    value={index}
                    onClick={() => scrollToSection(index)}
                    label={category.designation.toLowerCase()}
                    // sx={{
                    //   color: `${index == activeTabIndex ? "current-active-tab" : "black"}`,
                    //   "&.Mui-selected": {
                    //     color: "red",
                    //   },
                    // }}
                  />
                );
              })}
            </Tabs>
          </Box>

          {/* Type Order Tabs */}
          <section className="filter-cat-by-place tabs-menu-section">
            {filteredTabs.length > 0 && (
              <div className="wrapper-tab">
                {filteredTabs.map((typeOrderTab, index) => (
                  <p
                    key={index}
                    className={
                      typeOrder == typeOrderTab.type ? "active-tab" : ""
                    }
                    onClick={() => {
                      if (
                        typeOrderTab.type == MODE_DELIVERY &&
                        restaurantCart.mode != MODE_DELIVERY
                      ) {
                        setShowModal(true);
                      } else {
                        setTypeOrder(typeOrderTab.type);
                      }
                    }}
                  >
                    {typeOrderTab.label}
                  </p>
                ))}
              </div>
            )}
          </section>

          {/* categories Grid */}
          <Stack
            className="menuGrid"
            spacing={4}
            direction="column"
            sx={{ marginTop: "2rem" }}
          >
            {filteredCategories?.map((category: Category, i: number) => {
              return (
                <Stack
                  className="menuSection full-width menuSectionContainer"
                  key={i}
                  id={"menuSection" + i}
                  spacing={2}
                >
                  <div className="name-cat">
                    <p>{category.designation.toLowerCase()}</p>
                  </div>
                  {restaurant && (
                    <MenuRender
                      typeOrder={typeOrder}
                      category={category}
                      restaurant={restaurant}
                    />
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
      <AddressSelectionModal
        showModal={showModal}
        restaurantId={String(restaurantId)}
        hideModal={() => setShowModal(false)}
        onConfirmAddress={handleConfirmAddress}
      />
      <RestaurantErrorModal
        showRestaurantError={showRestaurantError}
        onPressBtn={handlePressbtnModal}
        onCloseModal={handleCloseErrorModal}
        modalTitle={languageData.fr.delivery.deliveryErrorModal.title}
        modalDescription={languageData.fr.delivery.deliveryErrorModal.message}
        modalButtonLabel={
          languageData.fr.delivery.deliveryErrorModal.actionButtonLabel
        }
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    activeRestaurant: state.menu.activeRestaurant,
    menu: state.menu.menu,
    menue: state.menu,
    menuLoading: state.menu.menuLoading,
    menuAvailableMode: state.menu.menuAvailableMode,
    mode: state.menu.mode,
    menuId: state.menu.menuId,
    savedCart: state.cart.savedCart,
    preSavedCart: state.cart.preSavedCart,
    activeRestaurantId: state.menu.activeRestaurantId,
    savedCategories: state.cart.savedCategories,
    token: state.auth.token,
    userInfo: state.auth.userInfo,
    categoryShopMode: state.category.categoryShopMode,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectCategory: (
      category: serviceMenuModels.Category,
      menuId: number,
      restaurantId: number
    ) => dispatch(selectCategory(category, menuId, restaurantId)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    addRestaurantToFavorite: (idRestaurant: number, value: boolean) =>
      dispatch(addShopToFavorite(idRestaurant, value)),
    logout: () => dispatch(logout()),
    changeCategoryShopMode: (value: number) =>
      dispatch(categoryShopsChangeShopMode(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(RestaurantMenuPage));
