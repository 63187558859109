import React from "react";
import FavoriteCircleIcon from "../../images/FavoriteCircleIcon.svg";
import RightArrowGrey from "../../images/RightArrowGrey.svg";

/**
 * ActivitieCard component description.
 *
 * @ActivitieCard
 *
 */

interface ActivitieCardProps {
  commandNumber?: number;
  commandDate?: string;
  points: number;
  earned?: boolean;
}

const ActivitieCard: React.FC<ActivitieCardProps> = ({
  commandNumber,
  commandDate,
  points,
  earned,
}) => {
  return (
    <div className="ActivitieCard">
      <div className="card-wrapper fl_row ai_c jc_s">
        <div className="left-side-card fl_row ai_c">
          <div className="FavoriteIcon">
            <img src={FavoriteCircleIcon} alt="" />
          </div>
          <div className="fl_col">
            <p className="command-label">
              {points} FID
              <span>{earned ? "Gagnés" : "Dépensés"}</span>
            </p>
            <p className="command-info">
              Commande N° #{commandNumber}, {commandDate}
            </p>
          </div>
        </div>
        <img width={"25px"} src={RightArrowGrey} alt="" />
      </div>
    </div>
  );
};

export default ActivitieCard;
