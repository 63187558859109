import { useEffect, useState } from "react";
import { CategoryItem } from "../../../interfaces/Catalogue";
import { filterCategoryItemsByOrderType } from "../../../utils/menuUtils";
import MenuItemSkeleton from "../../shared/Skeletons/LevelSkeleton/MenuItemSkeleton";
import MenuItem from "../MenuItem/MenuItem";
import "./menuList.scss";

interface MenuListProps {
  loading: boolean;
  categoryItems: CategoryItem[] | null;
  categoryId: number;
  isSubMenu?: boolean;
  typeOrder?: number;
  restaurantId: number | undefined;
  restaurantLogo: string;
}

const MenuList = (props: MenuListProps) => {
  const {
    restaurantId,
    loading,
    categoryId,
    categoryItems,
    typeOrder,
    restaurantLogo,
    isSubMenu,
  } = props;
  const [filtredCategories, setFiltredCategories] = useState<CategoryItem[]>([]);

  useEffect(() => {
    setFiltredCategories(
      filterCategoryItemsByOrderType(categoryItems || [], typeOrder || 0)
    );
  }, [categoryItems,typeOrder]);

  if (loading)
    return (
      <div className="listing-menu-section menuGrid">
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <MenuItemSkeleton key={index} />
          ))}
      </div>
    );

  if (!loading && categoryItems && categoryItems.length > 0) {
    return (
      <div className="listing-menu-section menuGrid">
        {filtredCategories?.map((categoryItem: CategoryItem, i: any) => {
          let isShortCard = false;
          return (
            <div className="listing-menu-section-element" key={i}>
              <MenuItem
                typeOrder={typeOrder}
                categoryId={categoryId}
                categoryItem={categoryItem}
                isFinalProduct={isShortCard}
                isSubMenu={isSubMenu}
                restaurantId={restaurantId}
                restaurantLogo={restaurantLogo}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return <div>Aucun menu disponible.</div>;
};

export default MenuList;
