import './DeliveryModeConflictAlert.scss';
import React from 'react';

interface DeliveryModeConflictAlertProps {
  isVisible: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeliveryModeConflictAlert: React.FC<DeliveryModeConflictAlertProps> = ({
  isVisible,
  message,
  onConfirm,
  onCancel,
}) => {
  if (!isVisible) return null;

  return (
    <>
      <div className="overlay" />
      <div className="delivery-mode-conflict-alert">
        <p>{message}</p>
        <div className="actions">
          <button onClick={onConfirm} className="confirm-button">
            Oui
          </button>
          <button onClick={onCancel} className="cancel-button">
            Non
          </button>
        </div>
      </div>
    </>
  );
};

export default DeliveryModeConflictAlert;
