import { Typography } from "@mui/joy";
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AllergenChip from "../../../images/icons/AllergenChip";
import { ApiResponse } from "../../../interfaces/ApiResponse";
import {
  CATEGORY_ITEM_TYPE,
  CategoryItem,
  GroupedArticleByLevel,
  Ingredient,
  MenuItem,
  OrderItem,
  Restaurant,
  SelectedIngredient,
} from "../../../interfaces/Catalogue";
import { catalogueApi } from "../../../services/catalogueApi";
import { pushCategory } from "../../../store/actions/actions";
import { getIcon, IconType } from "../../../theme/icons";
import { useConfig } from "../../../context/ConfigContext";
import DeliveryModeConflictAlert from "../../shared/Modals/DeliveryModeConflictAlert/DeliveryModeConflictAlert";
import LevelSkeleton from "../../shared/Skeletons/LevelSkeleton/LevelSkeleton";
import IngredientList from "../Ingredient/IngredientList/IngredientList";
import LevelList from "../Level/LevelList/LevelList";
import "./OrderInfo.scss";
import { findPriceByTypeOrder, getPriceByTypeOrderForComposition } from "../../../utils/priceUtils";
import { getCategoryItemType, groupMenuItemsByLevel } from "../../../utils/menuUtils";

type ScrollToLevelFunction = (levelId: number) => void;

interface OrderInfoProps {
  categoryId: string | undefined;
  categoryItemType: string | undefined;
  categoryItem: CategoryItem;
  typeOrder: number;
  restaurant: Restaurant;
  // priceByTypeOrder: number;
}

const OrderInfo = (props: OrderInfoProps) => {
  const { categoryItemType, categoryItem, typeOrder, categoryId, restaurant } =
    props;

  const { config } = useConfig();
  const { isMenu, isArticle } = getCategoryItemType(String(categoryItemType));
  const scrollToLevelRef = useRef<((index: number) => void) | null>(null);
  const priceByTypeOrder = findPriceByTypeOrder(
    categoryItem?.typeOrderCategory,
    typeOrder
  );
  const unitPrice = priceByTypeOrder || 0;
  const stackRef = useRef<HTMLDivElement | null>(null);
  const [totalPrice, setTotalPrice] = useState(unitPrice);
  const [finalUnitPrice, setFinalUnitPrice] = useState(unitPrice);

  const [levels, setLevels] = useState<GroupedArticleByLevel[]>([]);
  const [showPanierAlert, setShowPanierAlert] = useState(false);
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [selectedIngredients, setSelectedIngredient] = useState<
    SelectedIngredient[]
  >([]); // FOR ARTICLE
  const [eliminatedIngredients, setEliminatedIngredients] = useState<
    SelectedIngredient[]
  >([]);
  const [articleCount, setArticleCount] = useState<number>(1);
  const [openAllg, setOpenAllg] = useState<boolean>(false);
  const [incompleteLevel, setIncompleteLevel] =useState<number>()
  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<OrderItem[]>([]);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const dispatch = useDispatch();
  const savedCategories = useSelector(
    (state: any) => state?.cart?.savedCategories
  );
  const navigate = useNavigate();

  const onOrderGenerated = (orderItem: OrderItem) => {
    setOrder((prevItems) => {
      // Filter out existing items with the same level.id
      const updatedItems = prevItems?.filter(
        (item) => item?.level?.id !== orderItem?.level?.id
      );

      // Add the new orderItem
      return [...updatedItems, orderItem];
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isMenu) {
      let orderPrice = 0;

      order?.forEach((orderItem) => {
        let levelPrice = 0;

        orderItem?.selectedArticles?.forEach((selectedArticle) => {
          // Get the price for the selected article based on its type
          const priceByTypeOrder =
            findPriceByTypeOrder(
              selectedArticle?.selecteMenuItem?.typeOrderPrices,
              typeOrder
            ) || 0;

          // Calculate the total price for the selected article
          levelPrice +=
            priceByTypeOrder *
            (selectedArticle?.selecteMenuItem?.quantity ?? 1);

          // Calculate the price for selected ingredients
          selectedArticle?.selectedIngredients?.forEach((ingredient) => {
            const priceIngredient = getPriceByTypeOrderForComposition(
              typeOrder,
              ingredient?.ingredient
            );

            // Update levelPrice with the ingredient price multiplied by quantity
            levelPrice +=
              priceIngredient *
              ingredient?.quantity *
              (selectedArticle?.selecteMenuItem?.quantity ?? 1);
          });
        });

        // Update the total order price with the level price of this order item
        orderPrice += levelPrice;
      });

      // Set the final unit price
      setFinalUnitPrice(orderPrice);
    }
  }, [order]);

  useEffect(() => {
    if (categoryItemType == CATEGORY_ITEM_TYPE.MENU) {
      fetchLevels();
    }

    if (categoryItemType == CATEGORY_ITEM_TYPE.ARTICLE) {
      fetchIngredient(Number(categoryItem.idRef));
    }
  }, []);

  useEffect(() => {
    const totalPrice = (finalUnitPrice || 0) * articleCount;
    const formattedTotalPrice = parseFloat(totalPrice.toFixed(2));
    setTotalPrice(formattedTotalPrice);
  }, [articleCount, finalUnitPrice]);

  const fetchLevels = async () => {
    try {
      setLoading(true);
      const response: { data: ApiResponse<MenuItem[]> } =
        await catalogueApi.GetLevelsByMenuId(
          categoryId,
          Number(categoryItem.id),
          restaurant?.azureUrl || ""
        );
      const result = response?.data?.data?.result;
      const groupedLevels = groupMenuItemsByLevel(result, typeOrder);

      setLevels(groupedLevels);
    } catch (error: any) {
      console.error("🚀 ~ fetchCategoryDetails ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchIngredient = async (articleId: number) => {
    try {
      setLoading(true);
      const response: { data: ApiResponse<Ingredient[]> } =
        await catalogueApi.GetArticleComposition(
          articleId,
          false,
          restaurant?.azureUrl
        );
      setIngredients(response?.data?.data?.result);
    } catch (error: any) {
      console.error("🚀 ~ fetchCategoryDetails ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryCount = (type: string) => {
    if (type === "INCREASE") {
      setArticleCount((prevCount) => prevCount + 1);
    } else if (type === "DECREASE" && articleCount > 1) {
      setArticleCount((prevCount) => prevCount - 1);
    }
  };

  const handleClick = async () => {
    if (isAddingToCart) return; // Prevent multiple clicks if already adding to cart

    setIsAddingToCart(true);
    try {
      await addToCart();
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      setIsAddingToCart(false); // Re-enable button after processing
    }
  };

  const findIncompleteLevel = (
    order: OrderItem[],
    scrollToLevel: ScrollToLevelFunction
  ): OrderItem | null => {
    const mandatoryLevels = order?.filter(
      ({ level }) => level?.niveauMinSelection > 0
    );

    const incompleteLevel = mandatoryLevels?.find(
      ({ selectedArticles, level }) => {
        const niveauSelectionCount = selectedArticles.reduce(
          (prev, article) => prev + (article?.selecteMenuItem?.quantity || 0),
          0
        );
        return niveauSelectionCount < level.niveauMinSelection;
      }
    );

    if (incompleteLevel) {
      const { level } = incompleteLevel;
      scrollToLevel(level?.id);
      setIncompleteLevel(level?.id)
      return incompleteLevel;
    }
    return null;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIncompleteLevel(0);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [incompleteLevel]);

  const addToCart = async () => {
    if (isMenu && scrollToLevelRef.current) {
      const incompleteLevel = findIncompleteLevel(
        order,
        scrollToLevelRef.current
      );
      if (incompleteLevel) {
        return;
      }
    }
    if (
      savedCategories[restaurant?.id] &&
      savedCategories[restaurant?.id]?.mode !== typeOrder
    ) {
      setShowPanierAlert(true);
      return;
    }

    handleConfirmOrder();
  };

  const handleConfirmOrder = () => {
    if (showPanierAlert) {
      setShowPanierAlert(false);
    }
    console.log("handleConfirmOrder");

    if (isMenu) {
      categoryItem.order = order;
    }

    if (isArticle) {
      categoryItem.selectedIngredients = selectedIngredients;
      categoryItem.eliminatedIngredients = eliminatedIngredients;
    }

    categoryItem.quantity = articleCount;
    categoryItem.unitPrice = unitPrice;
    categoryItem.finalUnitPrice = finalUnitPrice;

    let ordersOfcurrentRestaurant: OrderItem[] =
      savedCategories[restaurant?.id]?.categories &&
      savedCategories[restaurant?.id]?.mode === typeOrder
        ? [...savedCategories[restaurant?.id]?.categories, categoryItem]
        : [categoryItem];

    let orders = savedCategories;

    orders[restaurant?.id] = {
      restaurant: restaurant,
      categories: ordersOfcurrentRestaurant,
      mode: typeOrder,
      menuId: Number(categoryId),
    };

    dispatch(pushCategory(orders));
    localStorage.setItem("redirectedToPrevPage", "true");
    navigate(`/restaurant/${restaurant?.id}`, {
      state: { mode: typeOrder },
      replace: true,
    });
  };

  const handleCancelOrder = () => {
    setShowPanierAlert(false);
    console.log("handleCancelOrder");
  };

  const handleClickAllergen = () => {
    setOpenAllg(true);
    console.log("AllergenChip clicked");
  };

  const updateSelectedIngredients = (
    selectedIngredients: SelectedIngredient[]
  ) => {
    let orderPrice: number = priceByTypeOrder || 0;

    selectedIngredients?.map((ingredient) => {
      const priceIngredient = getPriceByTypeOrderForComposition(
        typeOrder,
        ingredient?.ingredient
      );
      orderPrice += priceIngredient * ingredient.quantity;
    });

    const inclusIngredient = ingredients.filter(
      (ingredient) =>
        ingredient.inclus &&
        !selectedIngredients.some((selected) => selected.id === ingredient.id)
    );

    setFinalUnitPrice(parseFloat(orderPrice.toFixed(2)));
    setSelectedIngredient(selectedIngredients);
    setEliminatedIngredients(
      inclusIngredient.map((ingredient) => ({
        id: ingredient.id,
        ingredient,
        quantity: 0,
      }))
    );
  };

  return (
    <div id="OrderInfo">
      {/* Category Info Section */}
      <Stack className="mainMenuInfos" direction={"column"} spacing={2}>
        <Stack direction={"column"}>
          <p className="name-cat">{categoryItem?.designation}</p>
          <p className="unit-price">
            {unitPrice !== 0 ? `${unitPrice.toFixed(2)} €` : "\u00A0"}
          </p>
        </Stack>
        <p className="description">{categoryItem?.description} </p>

        {/* Quantity Section */}
        <div className="qtyManagerSection">
          <Box>
            <Stack
              className="qtyManager"
              direction="row"
              spacing={2}
              justifyContent="flex-start"
            >
              {getIcon(IconType.MINUS, {
                color: config?.primaryColor,
                onClick: () => handleCategoryCount("DECREASE"),
              })}

              <Typography
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  color: "background: #747474;",
                  fontSize: "14px",
                }}
              >
                {articleCount}
              </Typography>
              {getIcon(IconType.PLUS, {
                color: config?.primaryColor,
                onClick: () => handleCategoryCount("INCREASE"),
              })}
              {/* <img
                onClick={() => {
                  handleCategoryCount("INCREASE");
                }}
                src={PlusboxOrangeIcon}
                alt="PlusboxOrangeIcon"
              /> */}
            </Stack>
          </Box>
        </div>

        <div>
          <Stack spacing={1} flex={1} direction={"row"}>
            {categoryItem?.hasAllergene && (
              <AllergenChip onClick={handleClickAllergen} />
            )}
          </Stack>
        </div>
      </Stack>

      {isMenu && (
        <Stack className="menuProducts" spacing={2} ref={stackRef}>
          {loading ? (
            Array.from({ length: categoryItem.menuLevelsCount })?.map(
              (_, index) => <LevelSkeleton key={index} />
            )
          ) : (
            <LevelList
              levels={levels}
              typeOrder={typeOrder}
              onOrderGenerated={onOrderGenerated}
              scrollToLevel={scrollToLevelRef}
              incompleteLevel={incompleteLevel || 0}
            />
          )}
        </Stack>
      )}

      {isArticle && (
        <Stack
          className="menuProducts"
          spacing={2}
          ref={stackRef}
          style={{ marginBottom: "36px", marginTop: "20px" }}
        >
          <IngredientList
            ingredients={ingredients}
            typeOrder={typeOrder}
            updateSelectedIngredients={updateSelectedIngredients}
            article={categoryItem}
            compositionMaxSelection={categoryItem?.maxNbrSelectionComposition}
          />
        </Stack>
      )}

      <Button
        className="customFilled btnAddToCart"
        variant="contained"
        sx={{ display: { xs: "none", md: "block" } }}
        disabled={isAddingToCart}
        onClick={handleClick}
      >
        Ajouter {articleCount} au panier {totalPrice > 0 && `${totalPrice}€`}
      </Button>

      <div className="FooterContainer">
        <Button
          className="customFilled btnAddToCart"
          variant="contained"
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={handleClick}
        >
          Ajouter {articleCount} au panier {totalPrice > 0 && `${totalPrice}€`}
        </Button>
      </div>
      <DeliveryModeConflictAlert
        message="Votre panier contient déjà des articles avec un autre mode de livraison. Voulez-vous effacer les articles existants ?"
        onConfirm={handleConfirmOrder}
        onCancel={handleCancelOrder}
        isVisible={showPanierAlert}
      />
    </div>
  );
};

export default OrderInfo;
