import { useLocation } from "react-router-dom";
import { useConfig } from "../../../../../context/ConfigContext";
import { RestaurantCart } from "../../../../../interfaces/Catalogue";
import { getIcon, IconType } from "../../../../../theme/icons";
import "./panierItem.scss";
import { getOrderTypeText, MODE_DELIVERY } from "../../../../../constants/constants";
import { getTotalOrder } from "../../../../../utils/priceUtils";


interface PanierItemProps {
  restaurantCart: RestaurantCart;
  showRestaurantCart: (restaurantCart: RestaurantCart) => void;
}

const PanierItem = (props: PanierItemProps) => {
  const { restaurantCart, showRestaurantCart } = props;
  const { config } = useConfig();
  const location = useLocation()
  const orderTypeText = getOrderTypeText(restaurantCart?.mode);
  const totalOrder = getTotalOrder(restaurantCart.categories);
  const totalQuantity = restaurantCart?.categories?.reduce(
    (acc: number, curr: any) => acc + curr?.quantity,
    0
  );

  return (
    <div className="hovering">
      <div className="items-list ">
        <div
          className="items-wrapper"
          onClick={() => {
            showRestaurantCart(restaurantCart);
          }}
        >
          <div className="left-side-item">
            <img
              src={restaurantCart?.restaurant?.imageUrl}
              alt={restaurantCart?.restaurant?.nom}
            />
          </div>
          <div className="item-info">
            <div className="item-info-wrapper">
              <div className="item-details">
                <div className="product">
                  <p className="product-name">
                    {restaurantCart?.restaurant?.nom}
                  </p>
                  <p className="quantity-count">{totalQuantity}</p>
                </div>
                <div className="sub-description">
                  <p className="sous-total">
                    Sous-Total : {totalOrder.toFixed(2)} €
                  </p>
                  <p className="adresse">
                    {orderTypeText}
                    {restaurantCart?.mode == MODE_DELIVERY &&
                      'location?.shownAddress '
                    }
                  </p>
                </div>
              </div>
              <div className="show-item">
                {getIcon(IconType.RIGHT_ARROW,{color: config?.primaryColor,})}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanierItem;
