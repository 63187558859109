import React from 'react';
import moment from 'moment';

interface Shift {
  isCurrentShift: boolean;
  open: string;
  close: string;
}

interface ShiftInfoProps {
  currentShifts: Shift | null | undefined;
}

const ShiftInfo: React.FC<ShiftInfoProps> = ({ currentShifts }) => {
  if (!currentShifts) return <>Fermé {" • "}</>; 

  return (
    <>
      {currentShifts.isCurrentShift ? "Fermé" : "Ouvert"} à{" "}
      {currentShifts.isCurrentShift
        ? moment(currentShifts.close, "HH:mm").format("HH:mm")
        : moment(currentShifts.open, "HH:mm").format("HH:mm")}{" • "}
    </>
  );
};

export default ShiftInfo;
