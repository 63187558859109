import { DeliveryQuote } from "../interfaces/delivery";
import { extractClientAddress } from "../utils/deliveryUtils";
import { authAxios, buildUrl } from "./apiGate";

export const deliveryApi = {

  checkRestaurantDelivery: async (
    restaurantId: string,
    deliveryAddress: google.maps.places.PlaceResult | undefined,
    clientPhoneNumber?: string,
    orderTotalPrice?: string
  ) => {
    if (!deliveryAddress) {
      throw new Error('Address is required');
    }

    const clientAddress = extractClientAddress(deliveryAddress || {})
    const location = deliveryAddress?.geometry?.location;

    let lat: number = 0;
    let lng: number = 0;

    if (location) {
      if (typeof location.lat === 'function' && typeof location.lng === 'function') {
        lat = location.lat();
        lng = location.lng();
      } else {
        lat = location?.lat as any ?? 0;
        lng = location?.lng as any ?? 0;
      }
    }



    const params = {
      clientLatitude: String(lat),
      clientLongitude: String(lng),
      "ClientAddress.appartement": clientAddress.appartement || '----',
      "ClientAddress.city": clientAddress.city,
      "ClientAddress.country": clientAddress.country,
      "ClientAddress.zip_code": clientAddress.zip_code,
      "ClientAddress.streetAddress": clientAddress.streetAddress,
      "ClientAddress.state": clientAddress.state,
      ...(clientPhoneNumber && { ClientPhoneNumber: clientPhoneNumber }),
      ...(orderTotalPrice && { OrderTotalPrice: orderTotalPrice }),
      establishementId: restaurantId,
  };
  

    const url = buildUrl('/createDeliveryQuote', params);
    return authAxios.post<DeliveryQuote>(url);
  },

}