import {
  Button,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import { useConfig } from "../../../context/ConfigContext";
import { languageData } from "../../../i18n";
import appleBadge from "../../../images/Mobile-app-store_badge.svg";
import googlePlayBadge from "../../../images/Mobile-play-store_badge.svg";
import { isColorDark } from "../../../utils/colorsUtils";
import "./Footer.scss";
import { navigateToApple, navigateToGoogle } from "../../../utils/navigationUtils";

export default function Footer() {
  const { config } = useConfig();
  const logo = isColorDark(config?.layoutColor || "") ? config?.appLightLogoImage : config?.appDarkLogoImage;
  
  return (
    <footer id="footerContainer" className="">
      <div className="footer-wrapper">
        <div className="left-side">
          <div className="side-wrapper sideSection column">
            <div className="footer-logo">
              <img className="logo_footer" src={logo} alt="app-logo" />
              <Typography>{languageData.fr.Footer.str_01}</Typography>
            </div>
            <div className="side-wrapper row buttons-box mobile-footer">
              <Button className="row" onClick={navigateToApple}>
                <img src={appleBadge} alt="" className="badgeImg" />
              </Button>
              <Button className="row" onClick={navigateToGoogle}>
                <img src={googlePlayBadge} alt="" className="badgeImg" />
              </Button>
            </div>
            <div className="sub-list-footer">
              <Typography>
                <ul className="footer-menu">
                  <li>
                    <Link to="/apropos">{languageData.fr.Footer.str_02}</Link>
                  </li>
                  <li>
                    <Link to="/apropos-politique">{languageData.fr.Footer.str_03}</Link>
                  </li>
                  <li>{languageData.fr.Footer.str_04}</li>
                  <li>{languageData.fr.Footer.str_05}</li>
                  <li>{languageData.fr.Footer.str_06}</li>
                </ul>
              </Typography>
            </div>
          </div>
        </div>
        <div className="right-side column">
          <div style={{gap:"10px"}} className="side-wrapper row buttons-box desktop-footer">
            <Button style={{padding:"0"}} className="row" onClick={navigateToApple}>
              <img src={appleBadge} alt="" className="badgeImg" />
            </Button>
            <Button style={{padding:"0"}} className="row" onClick={navigateToGoogle}>
              <img src={googlePlayBadge} alt="" className="badgeImg" />
            </Button>
          </div>
          <Typography className="copyright">© 2024 FRENCHINNOV.</Typography>
        </div>
      </div>
    </footer>
  );
}
