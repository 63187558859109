export interface LoyaltyEstablishment {
  establishmentId: number;
  establishmentName: string;
  totalLoyaltyPoints: number;
}

export interface LoyaltyActivity{
  commandNumber: number;  
  commandDate: string; 
  points: number;         
  earned: boolean;      
}

export const loyaltyActivitiesData: LoyaltyActivity[] = [
    {
      commandNumber: 911685,
      commandDate: "2023-03-04",
      points: 53,
      earned: true,
    },
    {
      commandNumber: 245808,
      commandDate: "2023-12-22",
      points: 100,
      earned: true,
    },
    {
      commandNumber: 136664,
      commandDate: "2023-04-28",
      points: 58,
      earned: false,
    },
    {
      commandNumber: 161390,
      commandDate: "2023-04-24",
      points: 61,
      earned: true,
    },
    {
      commandNumber: 341261,
      commandDate: "2023-01-08",
      points: 45,
      earned: true,
    },
    {
      commandNumber: 658947,
      commandDate: "2023-09-13",
      points: 90,
      earned: false,
    },
    {
      commandNumber: 482304,
      commandDate: "2023-07-19",
      points: 77,
      earned: true,
    },
    {
      commandNumber: 738495,
      commandDate: "2023-11-01",
      points: 65,
      earned: false,
    },
    {
      commandNumber: 574839,
      commandDate: "2023-02-18",
      points: 30,
      earned: true,
    },
    {
      commandNumber: 391204,
      commandDate: "2023-05-30",
      points: 50,
      earned: false,
    },
  ];
  