import { NavigateFunction } from "react-router-dom";
import { Config } from "../config";

export const navigateBasedOnRestaurantCount = (
  restaurantCount: number, 
  isGlobalWebsite:boolean,
  navigate: NavigateFunction
) => {
  if (restaurantCount > 1 || isGlobalWebsite) {
    navigate("/restaurants");
  } else {
    navigate("/");
  }
};

export const navigateToApple = () => {
  window.open(Config.iosAppUrl, "_blank");
};

export const navigateToGoogle = () => {
  window.open(
    Config.androidAppUrl,
    "_blank"
  );
};