import moment from 'moment';
import { Restaurant } from '../interfaces/Catalogue';

/**
 * Finds the current shift for the given shop item.
 * If no shift is currently open, it finds the next open shift.
 *
 * @param item - The shop item.
 * @returns The current shift or null if no shift is open.
 */

export const findCurrentShift = (item: Restaurant) : any => {
    let currentShifArray = item?.shifts?.filter(
      shift => shift?.isCurrentShift === true,
    );
  
    if (!currentShifArray || currentShifArray?.length === 0) {
      const now = moment();
      const nextShift = item?.shifts?.find(shift => {
        const shiftStartDate = moment(shift.open, 'HH:mm');
        return now.isBefore(shiftStartDate);
      });
  
      if (nextShift) {
        console.log({nextShift});
        currentShifArray = [nextShift];
      }
    }
  
    return currentShifArray?.length > 0
      ? currentShifArray[0]
      : item?.shifts
      ? item?.shifts[0]
      : null;
  };