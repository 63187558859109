import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { languageData } from "../../../../i18n";
import CloseIcon from "../../../../images/CloseIcon_btn.svg";
import RestaurantIcon from "../../../../images/RestaurantIcon.svg";
import PanierIcon from "../../../../images/shopping-bag-01.svg";
import {
  Cart,
  Restaurant,
  RestaurantCart,
} from "../../../../interfaces/Catalogue";
import { shopApi } from "../../../../services/apiGate";
import {
  pushCategory,
  selectCategory,
} from "../../../../store/actions/actions";
import AuthLoginModal from "../../../shared/Modals/AuthLoginModal/AuthLoginModal";
import CartData from "./CartData/CartData";
import "./cartModalStyles.scss";

const CartModal = (props: any) => {
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const savedCategories = useSelector(
    (state: { cart: Cart }) => state.cart.savedCategories
  );
  const restaurantCart: RestaurantCart =
    savedCategories[Number(restaurantId)] || {};
  const [ShowCommandDetails, setShowCommandDetails] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [ShowRestaurantError, setShowRestaurantError] = useState(false);

  useEffect(() => {
    // Fetch the current cart length for the specific restaurant from local storage
    const currentCartLengthKey = `currentCartLength-${restaurantId}`;
    const storedCartLength = parseInt(
      localStorage.getItem(currentCartLengthKey) || "0",
      10
    );

    const prevCartCount =  isNaN(storedCartLength) || storedCartLength == null ? 0 : storedCartLength;
    const newCartCount =  isNaN(restaurantCart?.categories?.length) || restaurantCart?.categories?.length == null ? 0 : restaurantCart?.categories?.length;

    // Check if the cart length has changed
    if (prevCartCount == 0 ||  newCartCount == 0) {
      setShowCommandDetails(false);
    }else  if (Number(storedCartLength) !== Number(restaurantCart?.categories?.length)) {
      setShowCommandDetails(true);

      // Save the new currentCartLength to localStorage
      localStorage.setItem(
        currentCartLengthKey,
        restaurantCart?.categories?.length.toString()
      );
    }

  }, [restaurantCart?.categories, restaurantId]);

  useEffect(() => {
    const body = document.body;
    if (ShowCommandDetails) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [ShowCommandDetails]);

  const handleCommand = async (restaurantId: number) => {
    let disponible = await checkRestaurantDisponibilty();
    if (disponible) {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        localStorage.setItem("redirectedToAuth", "true");
        navigate("/Authentification");
      } else {
        localStorage.setItem("redirectedToAuth", "false");
        navigate("/paiement");
      }
    } else {
      setShowRestaurantError(!ShowRestaurantError);
    }
  };

  const [restaurant, setRestaurant] = useState<Restaurant>();

  useEffect(() => {
    const fetchRestaurantData = async () => {
      if (restaurantId) {
        try {
          const response = await shopApi.getResturantById({
            id: Number(restaurantId),
            diningMode: props.mode,
            adresse: {
              latitude: props.location?.lat,
              longitude: props.location?.lng,
            },
          });
          setRestaurant(response?.data?.restaurant);
        } catch (error) {
          console.error("Failed to fetch restaurant data:", error);
        }
      }
    };

    fetchRestaurantData();
  }, [restaurantId, props.mode, props.location]);

  const checkRestaurantDisponibilty = async () => {
    return restaurant?.isEnabled && restaurant?.isOpen;
  };

  let totalQuantity: number = 0;

  if (restaurantCart?.categories) {
    totalQuantity = restaurantCart.categories?.reduce((acc, category) => {
      const quantity = category?.quantity ?? 0;
      return acc + quantity;
    }, 0);
  }

  return (
    <div className={`CartNav ${ShowCommandDetails ? "showBox" : ""}`}>
      <div
        className="panier-btn"
        onClick={() => {
          setShowCommandDetails(!ShowCommandDetails);
          localStorage.setItem("redirectedToPrevPage", "false");
        }}
      >
        <div className="panier-el">
          <img src={PanierIcon} alt="" />
          <p className="alias-panier">Panier</p>
          <b style={{ color: "white" }}>•</b>
        </div>
        <p className="products-count">{totalQuantity}</p>
      </div>

      <CartData
        setShowCommandDetails={setShowCommandDetails}
        ShowCommandDetails={ShowCommandDetails}
        restaurantCart={restaurantCart}
        handleCommand={handleCommand}
        restaurant={restaurant as Restaurant}
      />

      <div className={`AuthModal ${showAuth ? "ShowAuth" : ""}`}>
        <div
          className="overlay-black"
          onClick={() => {
            setShowAuth(!showAuth);
            localStorage.setItem("redirectedToPrevPage", "false");
          }}
        ></div>

        <AuthLoginModal />
      </div>
      <div className={`restaurantError ${ShowRestaurantError ? "" : "hide"}`}>
        <div
          className="overlay-modal"
          onClick={() => setShowRestaurantError(!ShowRestaurantError)}
        ></div>
        <div className="modalContent">
          <div className="wrapper">
            <img
              className="close-btn"
              src={CloseIcon}
              alt=""
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            />
            <div>
              <p className="modalTitle">
                {languageData.fr.ModalRestaurantError.modalTitle}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RestaurantIcon} alt="" />
                <p className="modalDescription">
                  {languageData.fr.ModalRestaurantError.ModalDescription}
                </p>
              </div>
            </div>

            <button
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            >
              {languageData.fr.ModalRestaurantError.ModalButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    activeRestaurantId: state.menu.activeRestaurantId,
    preSavedCart: state.cart.preSavedCart,

    menuId: state.menu.menuId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectCategory: (
      restaurantCart: serviceMenuModels.Category,
      menuId: number,
      restaurantId: number
    ) => dispatch(selectCategory(restaurantCart, menuId, restaurantId)),
    pushCategory: (restaurantCart: serviceMenuModels.Category) =>
      dispatch(pushCategory(restaurantCart)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
