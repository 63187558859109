
import { SET_DELIVERY_ADDRESS } from './deliveryActionTypes';
import { SetDeliveryAddressAction } from './deliveryActions';

interface DeliveryState {
  deliveryAddress:  google.maps.places.PlaceResult | undefined | null;
}

const initialState: DeliveryState = {
  deliveryAddress: null,
};

const deliveryReducer = (state = initialState, action: SetDeliveryAddressAction): DeliveryState => {
  switch (action.type) {
    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload,
      };
    default:
      return state;
  }
};

export default deliveryReducer;
