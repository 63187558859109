import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageLazyLoader from "../../components/common/ImageLazyLoader";
import OrderInfo from "../../components/Order/OrderInfo/OrderInfo";
import AppNavbar from "../../components/shared/AppNavbar/AppNavbar";
import LeftArrow from "../../images/arrow-left_grey.svg";
import {
  CATEGORY_ITEM_TYPE,
  CategoryItem,
  Restaurant,
} from "../../interfaces/Catalogue";
import { shopApi } from "../../services/apiGate";
import { catalogueApi } from "../../services/catalogueApi";
import "./OrderPage.scss";
import Footer from "../../components/shared/Footer/Footer";
import { useConfig } from "../../context/ConfigContext";

const OrderPage = () => {
  const { config } = useConfig();
  const navigate = useNavigate();
  const {
    restaurantId,
    typeOrder,
    categoryId,
    categoryItemType,
    categoryItemId,
  } = useParams();
  const menuPage = true;
  const [isMobile, setIsMobile] = useState(false);
  const [categoryItem, setCategoryItem] = useState<CategoryItem>();
  const [restaurant, setRestaurant] = useState<Restaurant>();
  const [loading, setLoading] = useState(false);
  const [establishementToken, setEstablishementToken] = useState(
    localStorage.getItem("establishementToken")
  );

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await shopApi.getResturantById({
          id: Number(restaurantId),
          diningMode: Number(typeOrder),
          adresse: {
            latitude: 0,
            longitude: 0,
          },
        });
        const azureUrl = response?.data?.restaurant?.azureUrl;

        const tokenRes = await catalogueApi.getEstablishementToken(azureUrl);
        const newToken = tokenRes.data.data.result;

        localStorage.setItem("establishementToken", newToken);
        setEstablishementToken(newToken);
        setRestaurant(response.data?.restaurant);
      } catch (error) {
        console.error("Error fetching restaurant:", error);
      }
    };

    if (restaurantId) {
      fetchRestaurant();
    }
  }, [restaurantId]);

  useEffect(() => {
    const fetchCategoryItem = async () => {
      try {
        setLoading(true);
        const fetchFunction =
          categoryItemType === CATEGORY_ITEM_TYPE.ARTICLE
            ? catalogueApi.GetArticleById
            : categoryItemType === CATEGORY_ITEM_TYPE.MENU
              ? catalogueApi.GetMenuById
              : null;

        if (!fetchFunction) {
          throw new Error("Invalid category item type");
        }

        const response = await fetchFunction(
          Number(categoryItemId),
          Number(categoryId)
        );
        let item: CategoryItem = response.data.data.result;
        item.categoryId = Number(categoryId);
        setCategoryItem(item);
      } catch (error: any) {
        console.error("🚀 ~ fetchCategoryDetails ~ error:", error);
        // const errorCodeFI = error.response?.data?.failureResponse?.codeFI?.code;
        // const errorCodeReq = error.response?.status;
        // setError(errorCodeFI || errorCodeReq);
      } finally {
        setLoading(false);
      }
    };
    if (categoryItemType && establishementToken) {
      fetchCategoryItem();
    }
  }, [categoryItemType, establishementToken]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the pixel value as needed
    };

    checkMobile(); // Check on initial render
    window.addEventListener("resize", checkMobile); // Update on resize

    return () => window.removeEventListener("resize", checkMobile); // Cleanup
  }, []);

  return (
    <div id="orderPage">
      <AppNavbar menuPage={menuPage} />
      <div className="BodyPage">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <div className="TopPageHeader">
            <div>
              <img
                onClick={() => {
                  navigate(`/restaurant/${restaurantId}`);
                }}
                src={LeftArrow}
                alt=""
              />

              <p>{categoryItem?.designation}</p>
            </div>
          </div>
        </Box>

        <div className="orderView full-size row">
          {/* Left section */}
          {!isMobile && (
            <div className="leftSection column">
              <div className="imgContainer">
                <ImageLazyLoader
                  alt={categoryItem?.designation || restaurant?.nom}
                  src={categoryItem?.imgUrl || restaurant?.imageUrl || ""}
                  effect="blur"
                  ClassName="full-size"
                />
              </div>
            </div>
          )}

          <Stack className="rightSection column" spacing={2}>
            {/* Right section */}
            {restaurant && categoryItem ? (
              <OrderInfo
                categoryItemType={categoryItemType}
                categoryItem={categoryItem}
                typeOrder={Number(typeOrder)}
                categoryId={categoryId}
                restaurant={restaurant}
              />
            ) : (
              <div className="orderLoading"></div>
            )}
          </Stack>
        </div>
      </div>
      {/* {config?.isGlobalWebsite && <Footer />} */}
    </div>
  );
};

export default OrderPage;
