import { MODE_DELIVERY, MODE_ONSITE, MODE_TAKEAWAY } from "../constants/constants";
import { CategoryItem, Ingredient, TypeOrderPrice } from "../interfaces/Catalogue";

export const getTotalOrder = (categories: CategoryItem[]): number => {
    const calculatedTotal =
        categories?.length > 0
            ? categories?.reduce((total: number, category) => {
                return (
                    total + (category?.finalUnitPrice || 0) * (category?.quantity || 0)
                );
            }, 0)
            : 0;

    return parseFloat(calculatedTotal.toFixed(2))
}

// PRICE:
export const findPriceByTypeOrder = (
    typeOrderCategory: TypeOrderPrice[],
    typeOrder: number
): number | undefined => {
    const item = typeOrderCategory?.find(
        (orderCategory) => orderCategory.type === typeOrder
    );
    return item?.price || 0;
};

export const getPriceByTypeOrderForComposition = (typeOrder: number, articleComposition: Ingredient): number => {
    switch (typeOrder) {
        case MODE_ONSITE: // Sur Place
            return articleComposition.priceOnSite ?? 0;
        case MODE_TAKEAWAY: // A Emporter
            return articleComposition.priceEmporter ?? 0;
        case MODE_DELIVERY: // Livraison
            return articleComposition.priceDelivery ?? 0;
        default:
            return 0;
    }
}

export const centimesToEuros = (centimes: number): number => centimes / 100;