import React from "react";
import { Ingredient, SelectedIngredient } from "../../../../interfaces/Catalogue";
import IngredientCheckbox from "./types/IngredientCheckbox/IngredientCheckbox";
import IngredientQuantity from "./types/IngredientQuantity/IngredientQuantity";

interface IngredientItemProps {
  ingredient: Ingredient;
  typeOrder: number;
  selectedIngredients: SelectedIngredient[];
  totalSelectedNotInclus: number;
  compositionMaxSelection: number;
  className?: string;
  updateCheckedCompositions: (
    id: number,
    ingredient: Ingredient,
    quantity: number
  ) => void;
}

const IngredientItem = (props: IngredientItemProps) => {
  const {
    ingredient,
    selectedIngredients,
    typeOrder,
    totalSelectedNotInclus,
    compositionMaxSelection,
    updateCheckedCompositions,
    className
  } = props;
  const isUniSlection = ingredient.maxNbrSelection <= 1;
  const selectedIngredient = selectedIngredients.find(
    (item) => item.id === ingredient.id
  );

  const isSelected = !!selectedIngredient;
  const quantity = isSelected ? selectedIngredient?.quantity || 0 : 0;

  const handleCheckboxChange = () => {
    const newQuantity = isSelected ? 0 : 1;
    updateCheckedCompositions(ingredient.id, ingredient, newQuantity);
  };

  const handleQuantityChange = (newQuantity: number) => {
    updateCheckedCompositions(ingredient.id, ingredient, newQuantity);
  };

  return isUniSlection ? (
    <IngredientCheckbox
      ingredient={ingredient}
      isSelected={isSelected}
      onChange={handleCheckboxChange}
      typeOrder={typeOrder}
      disabled={
        !isSelected &&
        !ingredient.inclus &&
        totalSelectedNotInclus >= compositionMaxSelection
      }
      className={className}
    />
  ) : (
    <IngredientQuantity
      ingredient={ingredient}
      quantity={quantity}
      maxSelection={ingredient.maxNbrSelection}
      typeOrder={typeOrder}
      onQuantityChange={handleQuantityChange}
      totalSelectedNotInclus={totalSelectedNotInclus}
      compositionMaxSelection={compositionMaxSelection}
      className={className}
    />
  );
};

export default IngredientItem;
