import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FormControl, TextField } from "@mui/material";
import { passwordStrength } from "check-password-strength";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Eye from "../../assets/auth-icons/eye.svg";
import { NavAuth } from "../../components/Auth/NavAuth/NavAuth";
import withNavigateHook from "../../hooks/withNavigateHook";
import { languageData } from "../../i18n";
import { resetUser, signup, updateUserAuth } from "../../store/actions/actions";
import { validation } from "../../utils/Validation";
import "./AuthStyles.scss";

const PersonalInfoPage = (props: any) => {
  const [firstName, setFirstName] = useState<string>(
    props.authGoogleSuccess?.payloadWeb?.family_name
      ? props.authGoogleSuccess?.payloadWeb?.family_name
      : ""
  );
  const [lastName, setLastName] = useState<string>(
    props.authGoogleSuccess?.payloadWeb?.given_name
      ? props.authGoogleSuccess?.payloadWeb?.given_name
      : ""
  );
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [password_1, setpassword_1] = useState(false);
  const [password_2, setpassword_2] = useState(false);
  const [strengthPassword, setStrengthPassword] = useState<
    string | undefined
  >();

  window.addEventListener("popstate", (event) => {
    props.navigate("/");
  });

  useEffect(() => {
    if (props.user === null || props.user.length === 0) {
      props.navigate("/Authentification");
    }
  }, []);

  const handlePassword_1 = () => {
    setpassword_1(!password_1);
  };

  const handlePassword_2 = () => {
    setpassword_2(!password_2);
  };
  
  const handleSubmit = () => {
    const isFirstNameValid = validation.ValidateName(firstName);
    const isLastNameValid = validation.ValidateName(lastName);

    // Check if any field is empty
    if (firstName?.trim() === "") {
      setFirstNameError(
        languageData.fr.auth["login.input.lastname.placeholder"]
      );
      return;
    } else {
      setFirstNameError("");
    }

    if (lastName?.trim() === "") {
      // setLastNameErorr(
      //   languageData.fr.auth["login.input.firstanme.placeholder"]

      // );
      setLastNameError("Saisissez votre prénom");
      return;
    } else {
      setLastNameError("");
    }

    if (!isFirstNameValid) {
      setFirstNameError("Veuillez saisir un prénom valide");
      return;
    } else {
      setFirstNameError("");
    }

    if (!isLastNameValid) {
      setLastNameError("Veuillez saisir un nom de famille valide");
      return;
    } else {
      setLastNameError("");
    }

    // Check if password is empty
    if (password === "") {
      setErrorPassword(languageData.fr.auth["login.header.pass.obligatoire"]);
      return;
    } else {
      setErrorPassword("");
    }

    // Check password validation criteria
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated
    ) {
      setErrorPassword(
        "Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre, un caractère spécial et comporter au moins 8 caractères"
      );
      return;
    } else {
      setErrorPassword("");
    }
    if (confirmPassword !== password) {
      setErrorPassword(languageData.fr.auth["login.header.pass.confirm"]);
      return;
    } else {
      setErrorPassword("");
    }

    // If all conditions are met, update user info and navigate
    props.updateUserInfo({
      firstName: firstName,
      lastName: lastName,
      password: password,
    });

    // Now navigate to the terms-and-conditions page
    props.navigate("../terms-and-conditions");
  };

  let color;
  switch (strengthPassword) {
    case "Too weak":
      color = "red";
      break;
    case "Weak":
      color = "orange";
      break;
    case "Medium":
      color = "#F9C70C";
      break;
    case "Strong":
      color = "green";
      break;
    default:
      color = "inherit";
  }

  // const handleBack = ()=>{
  //   props.navigate("../")
  // }

  const handleChange = (value: any) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{8,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }

    setPassword(value);

    const strength = passwordStrength(value).value;
    setStrengthPassword(strength);
  };

  return (
    <div className="authPage ">
      <NavAuth />
      <div className="content complete-profile">
        <p className="tite-form">Compléter les informations</p>
        <small
          style={{
            textAlign: "left",
            width: "100%",
            display: "flex",
            paddingBottom: "5px",
          }}
        >
          Veuillez saisir nom et prénom
        </small>

        <FormControl fullWidth className="InfoForm" variant="standard">
          <TextField
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            fullWidth
            className="personalinput"
            variant="standard"
            placeholder="Votre Nom"
          ></TextField>
          <p className="error-msg">{firstNameError ? firstNameError : ""}</p>
        </FormControl>

        <FormControl fullWidth className="InfoForm" variant="standard">
          <TextField
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            fullWidth
            className="personalinput"
            variant="standard"
            placeholder="Votre Prénom"
          ></TextField>
          <p className="error-msg">{lastNameError ? lastNameError : ""}</p>
        </FormControl>

        <p style={{ marginTop: "33px", display: "flex" }} className="tite-form">
          Créer votre mot de passe
        </p>
        <FormControl fullWidth className="InfoForm" variant="standard">
          <small style={{ textAlign: "left", width: "100%" }}>
            Veuillez saisir votre mot de passe *
          </small>
          <div className="field-pw">
            <TextField
              type={password_1 ? "text" : "password"}
              onChange={(e) => handleChange(e.target.value)}
              fullWidth
              className="personalinput"
              variant="standard"
              placeholder="***********"
            ></TextField>
            <img onClick={handlePassword_1} src={Eye} alt="" />
          </div>
        </FormControl>
        <FormControl fullWidth className="InfoForm" variant="standard">
          <small style={{ textAlign: "left", width: "100%" }}>
            Confirmer Mot de passe *
          </small>
          <div className="field-pw">
            <TextField
              type={password_2 ? "text" : "password"}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              className="personalinput"
              variant="standard"
              placeholder="***********"
            ></TextField>
            <img onClick={handlePassword_2} src={Eye} alt="" />
          </div>
          <p className="error-msg">{errorPassword ? errorPassword : ""}</p>
          {/* <p>{errorPassword ? errorPassword : ""}</p>{" "} */}
          {/* Display password error */}
        </FormControl>
        <span>
          Niveau de sécurité :{" "}
          {password === "" ? (
            ""
          ) : (
            <div style={{ color: color }}>{strengthPassword}</div>
          )}
        </span>
        {/* validation tracker */}
        <main className="tracker-box">
          <div className={lowerValidated ? "validated" : "not-validated"}>
            {lowerValidated ? (
              <span className="list-icon green">
                <CheckIcon style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span className="list-icon">
                <CircleIcon />
              </span>
            )}
            <span className="label-verification">Minuscules</span>
          </div>
          <div className={upperValidated ? "validated" : "not-validated"}>
            {upperValidated ? (
              <span className="list-icon green">
                <CheckIcon style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span className="list-icon">
                <CircleIcon />
              </span>
            )}
            <span className="label-verification">Majuscules</span>
          </div>
          <div className={numberValidated ? "validated" : "not-validated"}>
            {numberValidated ? (
              <span className="list-icon green">
                <CheckIcon style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span className="list-icon">
                <CircleIcon />
              </span>
            )}
            <span className="label-verification">Nombre numérique</span>
          </div>
          <div className={specialValidated ? "validated" : "not-validated"}>
            {specialValidated ? (
              <span className="list-icon green">
                <CheckIcon style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span className="list-icon">
                <CircleIcon />
              </span>
            )}
            <span className="label-verification">Symbole (#&$)</span>
          </div>
          <div className={lengthValidated ? "validated" : "not-validated"}>
            {lengthValidated ? (
              <span className="list-icon green">
                <CheckIcon style={{ fontSize: "20px" }} />
              </span>
            ) : (
              <span className="list-icon">
                <CircleIcon />
              </span>
            )}
            <span className="label-verification">Minimum 8 caractères</span>
          </div>
        </main>

        <div id="navButtonGroup">
          <button onClick={() => props.navigate("/")} id="back">
            <NavigateBeforeIcon />{" "}
          </button>
          <button onClick={() => handleSubmit()} id="next">
            {" "}
            {"Suivant"} <NavigateNextIcon />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    verifyCodeSuccess: state.auth.verifyCodeSuccess,
    validationStepToken: state.auth.validationStepToken,
    validationSignupToken: state.auth.validationSignupToken,
    authGoogleSuccess: state.auth.authGoogleSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserInfo: (userInfo: any) => dispatch(updateUserAuth(userInfo)),
    resetUser: () => dispatch(resetUser()),
    userSignUp: (res: any) => dispatch(signup(res)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(PersonalInfoPage));
