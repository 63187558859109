import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "../../../../../images/CloseIcon_btn.svg";
import EmptyCart from "../../../../../images/empty-cartIcon.svg";
import {
  Restaurant,
  RestaurantCart,
} from "../../../../../interfaces/Catalogue";
import CartElements from "../CartElements/CartElements";
import CartHeader from "../CartHeader/CartHeader";
import "../cartModalStyles.scss";
import { MODE_DELIVERY } from "../../../../../constants/constants";
import { getTotalOrder } from "../../../../../utils/priceUtils";

interface CartDataProps {
  restaurantCart: RestaurantCart;
  handleCommand: (restaurantId: number) => void;
  setShowCommandDetails: (value: boolean) => void;
  ShowCommandDetails: boolean;
  restaurant: Restaurant;
}

export const CartData = (props: CartDataProps) => {
  const {
    handleCommand,
    setShowCommandDetails,
    ShowCommandDetails,
    restaurantCart,
    restaurant,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { categories, mode } = restaurantCart;
  const restaurantId = restaurant?.id;
  const totalAmount = getTotalOrder(categories);
  const [showEmptyPanier, setShowEmptyPanier] = useState(false);

  const navigateToShope = () => {
    setShowCommandDetails(false);
    navigate(`/restaurant/${restaurantId}`);
  };

  useEffect(() => {
    document
      .querySelector(".CommandInfo")
      ?.classList.remove("showCommandDetails");
  }, []);

  const CloseModal = () => {
    setShowEmptyPanier(!showEmptyPanier);
    document
      .querySelector(".CommandInfo")
      ?.classList.remove(".showCommandDetails");
  };

  console.log("restaurant", restaurant);

  const isDisabled =
    (totalAmount === 0 || totalAmount < (restaurant?.cartThreshold || 0)) &&
    mode === MODE_DELIVERY;

  return (
    <div
      className={`CartData CartElementSection ${
        categories === undefined || categories?.length === 0 ? "noItems" : ""
      }`}
    >
      <div
        className="closeBtn"
        style={{ padding: "10px", cursor: "pointer" }}
        onClick={() => {
          CloseModal();
          setShowCommandDetails(!ShowCommandDetails);
          localStorage.setItem("redirectedToPrevPage", "false");
        }}
      >
        <img className="" src={CloseIcon} alt="CloseIcon" />
      </div>

      {categories === undefined || categories?.length === 0 ? (
        <div className="panier-wrapper">
          <img className="EmptyCart" src={EmptyCart} alt="EmptyCart" />
          <p>Aucun Article ajouté</p>
          <span>Ajoutez des articles, ils seront affichés ici.</span>
        </div>
      ) : (
        <div>
          <div className="CartComponents">
            <CartHeader
              restaurantCart={restaurantCart}
              restaurant={restaurant}
            />
            <CartElements
              restaurantCart={restaurantCart}
              setShowCommandDetails={setShowCommandDetails}
            />
          </div>
          <div className="CartFooter">
            <button
              className={`btn-command ${isDisabled ? "btn-disabled" : ""}`}
              disabled={isDisabled}
              onClick={() => {
                localStorage.setItem("restaurantId", String(restaurant?.id));
                handleCommand(restaurant?.id);
              }}
            >
              Commander • {totalAmount}€
            </button>
            <button onClick={navigateToShope} className="btn-add-articles">
              Ajouter article(s)
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartData;
