import React, { useEffect } from "react";
import CloseIcon from "../../../../images/CloseIcon_btn.svg";
import RestaurantIcon from "../../../../images/RestaurantIcon.svg";
import "./RestaurantErrorModal.scss";

interface RestaurantErrorModalProps {
  showRestaurantError: boolean;
  modalTitle: string;
  modalDescription: string;
  modalButtonLabel: string;
  onCloseModal: () => void;
  onPressBtn: () => void;
}

const RestaurantErrorModal: React.FC<RestaurantErrorModalProps> = ({
  showRestaurantError,
  modalTitle,
  modalDescription,
  modalButtonLabel,
  onCloseModal,
  onPressBtn
}) => {

    const AddClassToBody = () => {
      const body = document.querySelector('body');
      if (showRestaurantError) {
        body?.classList.add("ModalEnabled");
      } else {
        body?.classList.remove("ModalEnabled");
      }
    };
  
    useEffect(() => {
      AddClassToBody()
    }, [showRestaurantError]);

  return (
    <div id="restaurantErrorModal">
      <div className={`restaurantError ${showRestaurantError ? "" : "hide"}`}>
        <div className="overlay-modal" onClick={onCloseModal}></div>
        <div className="modalContent">
          <div className="wrapper">
            <img
              className="close-btn"
              src={CloseIcon}
              alt="Close"
              onClick={onCloseModal}
            />
            <div>
              <p className="modalTitle">{modalTitle}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RestaurantIcon} alt="Restaurant Icon" />
                <p className="modalDescription">{modalDescription}</p>
              </div>
            </div>

            <button onClick={onPressBtn}>{modalButtonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantErrorModal;
