import { GraphicChart } from "./context/ConfigContext"
import backgroundDesktopImage from "../src/images/fioeat/background-desktop-fioeat.png";
import backgroundTabletImage from "../src/images/fioeat/background-desktop-fioeat.png";
import backgroundMobileImage from "../src/images/fioeat/background-desktop-fioeat.png";
import appDarkLogoImage from "../src/images/fioeat/appDarkLogoImage.svg";
import appLightLogoImage from "../src/images/fioeat/appLightLogoImage.svg";
import appLogoImage from "../src/images/fioeat/appLogoImage.svg";

export const Config = {
  iosAppUrl: 'https://apps.apple.com/tn/app/fioeat/id6449358188',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.fioeat&hl=en_US',
}

export const FioeatGraphicChart: GraphicChart = {
  appLogoImage,
  appDarkLogoImage,
  appLightLogoImage,
  backgroundDesktopImage,
  backgroundTabletImage,
  backgroundMobileImage,
  id: 0,
  companyId: 0,
  primaryColor: "#EE8113",
  secondaryColor: "#1d2a3a",
  layoutColor: "#1d2939",
  establishementCount: 0,
  establishementId: null,
  withCategoryWheel: true,
  hasDarkBackground: false,
  showSearchBar: true,
  isGlobalWebsite: true
}
