import { useState } from "react";
import {
  GroupedArticleByLevel,
  MenuItem,
  SelectedIngredient,
  SelectionMode,
} from "../../../../../../interfaces/Catalogue";
import MultiSelectionItem from "./MultiSelectionItem/MultiSelectionItem";

interface MultiSelectionListProps {
  level: GroupedArticleByLevel;
  typeOrder: number;
  updateSelectedArticles: (
    selecteMenuItem: MenuItem,
    selectedIngredients: SelectedIngredient[],
    eliminatedIngredients: SelectedIngredient[],
    mode: SelectionMode
  ) => void;
}

const MultiSelectionList = (props: MultiSelectionListProps) => {
  const { level, typeOrder, updateSelectedArticles } = props;
  const [quantityArray, setQuantityArray] = useState(
    Array(level?.menuItems?.length).fill(0)
  );

  const globalCount = () => {
    let sum = 0;
    for (let i = 0; i < quantityArray.length; i++) {
      sum += quantityArray[i];
    }
    return sum;
  };

  return (
    <div>
      {level?.menuItems?.map((menuItem: MenuItem, index: number) => (
        <MultiSelectionItem
          index={index}
          levelMaxSelection={level.niveauMaxSelection}
          menuItem={menuItem}
          typeOrder={typeOrder}
          quantityArray={quantityArray}
          globalCount={globalCount}
          setQuantityArray={setQuantityArray}
          updateSelectedArticles={updateSelectedArticles}
        />
      ))}
    </div>
  );
};

export default MultiSelectionList;
