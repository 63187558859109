import { MODE_DELIVERY, MODE_ONSITE, MODE_TAKEAWAY } from "../constants/constants";
import { TVA } from "../interfaces/Catalogue";
import { SelectedIngredient } from "../interfaces/Catalogue";

// TVA:
export const getTvaByOrderType = (orderType: number, tvaObj: any): TVA => {
    switch (orderType) {
        case MODE_ONSITE:
            return tvaObj.tvaOnSite;
        case MODE_TAKEAWAY:
            return tvaObj.tvaEmporter;
        case MODE_DELIVERY:
            return tvaObj.tvaDelivery;
        default:
            throw new Error('Invalid order type');
    }
};

// Utility function to compare arrays (e.g., for checking if ingredients are the same)
export const arraysEqual = (arr1: SelectedIngredient[], arr2: SelectedIngredient[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item.id === arr2[index].id); // Adjust comparison based on actual properties
};


