import { ClientAddress } from "../interfaces/apiPayloads";


export const extractClientAddress = (    deliveryAddress: google.maps.places.PlaceResult | undefined,): ClientAddress => {
  const getComponent = (type: string) =>
    deliveryAddress?.address_components?.find((component) => component.types.includes(type))?.long_name || "";

  return {
    streetAddress: `${getComponent("street_number")} ${getComponent("route")}`.trim(),
    city: getComponent("locality"),
    state: getComponent("administrative_area_level_1"),
    zip_code: getComponent("postal_code"),
    country: getComponent("country"),
    // FIXME:
    appartement: '----'
  };
};


export const calculateTotalDuration = (deliveryDuration: string, deliveryQuoteDuration: number): string => {
  // Split deliveryDuration into hours and minutes
  const [deliveryHours, deliveryMinutes] = deliveryDuration.split(':').map(Number);

  // Convert deliveryDuration to minutes
  const deliveryDurationInMinutes = (deliveryHours * 60) + deliveryMinutes;

  // Add deliveryQuote duration in minutes
  const totalDurationInMinutes = deliveryDurationInMinutes + deliveryQuoteDuration;

  // Convert back to hours and minutes
  const totalHours = Math.floor(totalDurationInMinutes / 60);
  const totalMinutes = totalDurationInMinutes % 60;

  // Format the result as 'HH:MM'
  return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
};


export const convertTimeToMinutes = (time: string): number => {
  // Split the time string into minutes and seconds
  const [minutes, seconds] = time.split(':').map(Number);

  // Convert total time to minutes (seconds divided by 60)
  return minutes + (seconds / 60);
};
