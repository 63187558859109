import { languageData } from "../i18n";

export const COUNTRY_CODES = {
  code: "+33",
  icon: "france",
};

export const MODE_ONSITE = 1;
export const MODE_TAKEAWAY = 2;
export const MODE_DRIVE = 3;
export const MODE_DELIVERY = 4;

export type TypeOrder = typeof MODE_ONSITE | typeof MODE_TAKEAWAY | typeof MODE_DRIVE | typeof MODE_DELIVERY;

export const typeOrderTabs = [
  {
    label: languageData.fr.DeliveryTabSwitcher.str_aemporter,
    type: MODE_TAKEAWAY,
  },
  {
    label: languageData.fr.DeliveryTabSwitcher.str_surplace,
    type: MODE_ONSITE,
  },
  {
    label: languageData.fr.DeliveryTabSwitcher.str_livraison,
    type: MODE_DELIVERY,
  },
];

export const getDiningModeLabel = (diningMode: number): string => {
  const diningModeLabels: Record<number, string> = {
    [MODE_ONSITE]: "sur Place",
    [MODE_TAKEAWAY]: "à emporter",
    [MODE_DRIVE]: "Drive",
    [MODE_DELIVERY]: "Livraison",
  };

  return diningModeLabels[diningMode] || "Mode inconnu";
}

export const getOrderTypeText = (typeOrder: number): string | null => {
    if (!typeOrder) return null;

    switch (typeOrder) {
        case MODE_TAKEAWAY:
            return "À Emporter"; // Takeaway
        case MODE_ONSITE:
            return "Sur place"; // Onsite
        case MODE_DELIVERY:
            return "Livraison"; // Delivery
        case MODE_DRIVE:
            return "Drive"; // Drive
        default:
            return "Mode inconnu"; // Unknown mode
    }
}