import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CardFidItem from "../../components/FIOFID/CardFidItem";
import AppNavbar from "../../components/shared/AppNavbar/AppNavbar";
import LeftArrow from "../../images/arrow-left_grey.svg";
import CopyIconGrey from "../../images/CopyIconGrey.svg";
import ImgRes from "../../images/Restaurant-img-test.png";
import SearchIcon from "../../images/SearchIconGrey.svg";
import { LoyaltyEstablishment } from "../../interfaces/loyality.interface";
import { loyalityApi } from "../../services/loyalityApi";
import { getmyprofile } from "../../store/actions/actions";
import { convertHexToByteArray } from "../../utils/imagesUtils";
import { handleCopy } from "../../utils/textUtils";
import "./FiofidPage.scss";

export default function FiofidPage() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  let byteArray = convertHexToByteArray(userInfo?.qrCodeBytes);
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  const qrCodeUrl = URL.createObjectURL(blob);

  const [RestorentFilter, setRestorentFilter] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);
  const [copied, setCopied] = useState(false);
  const [restaurants, setRestaurants] = useState<LoyaltyEstablishment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    dispatch(getmyprofile());
  }, []);

  useEffect(() => {
    const getLoyaltyRestaurants = async () => {
      try {
        setLoading(true);
        const data = await loyalityApi.fetchLoyaltyRestaurants(searchQuery);
        console.log("🚀 ~ getLoyaltyRestaurants ~ data:", data.data);
        setRestaurants(data.data);
      } catch (err: any) {
        console.log("Failed to load restaurants", err.message);
      } finally {
        setLoading(false);
      }
    };

    getLoyaltyRestaurants();
  }, [searchQuery]);

  const HandelQrCode = () => {
    setShowQrCode((prevState) => !prevState);
  };

  const handleClickCopy = (qrCodeValue: string) => {
    handleCopy(qrCodeValue);
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  return (
    <div className="FIOFID">
      <AppNavbar
        setRestorentFilter={setRestorentFilter}
        RestorentFilter={RestorentFilter}
      />
      <div className="TopPageHeader">
        <Link to="/">
          <div>
            <img src={LeftArrow} alt="" />
            <p>FIOFID</p>
          </div>
        </Link>
      </div>
      <div className="listing-cards">
        <div className="qr-code">
          <p className="title-code">
            Scannez le QR code pour profiter des avantages Fidélité !
          </p>
          <div className="fl_row ai_c qr-wrapper">
            <div className="qrCode fl_row" onClick={HandelQrCode}>
              <img width={"60px"} src={qrCodeUrl} alt="" />
            </div>
            <div className="code-info fl_col">
              <p className="title">Code FID</p>
              <div className="fl_row">
                <p className="code">{userInfo?.qrCodeValue}</p>
                {/* <img
                  src={CopyIconGrey}
                  alt="Copy Icon"
                  width="20px"
                  style={{ transform: copied ? "scale(1.1)" : "scale(1)" }}
                  onClick={() => handleClickCopy(userInfo?.qrCodeValue)}
                /> */}
              </div>
            </div>
          </div>
          <div className={`qrCode-large ${showQrCode ? "showQrCode" : ""}`}>
            <div className="overlay">
              <div className="wrapper">
                <img src={qrCodeUrl} alt="" />
                <button onClick={HandelQrCode}>Fermer</button>
              </div>
            </div>
          </div>
        </div>
        <div className="ListFilter fl_col">
          <p className="title-filter">Rechercher un restaurant</p>
          <div className="filter-field">
            <img src={SearchIcon} alt="" />
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              type="text"
              placeholder="Recherche..."
            />
          </div>
        </div>
        <div className="FidSection">
          <div className="section-wrapper">
            {loading ? (
              <div className="center-message">Loading...</div>
            ) : (
              <div className="list-section">
                {restaurants.length === 0 ? (
                  <div className="center-message">Aucun restaurant trouvé!</div>
                ) : (
                  restaurants.map((restaurant) => {
                    return (
                      <CardFidItem
                        key={restaurant.establishmentId}
                        restaurantName={restaurant.establishmentName}
                        restaurantImg={ImgRes}
                        points={restaurant.totalLoyaltyPoints}
                      />
                    );
                  })
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
