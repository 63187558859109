import { HashRouter, Route, Routes } from "react-router-dom";
import AboutPage from "../pages/AboutPage/AboutPage";
import AuthentificationPage from "../pages/auth/AuthentificationPage";
import ChangePasswordPage from "../pages/auth/ChangePasswordPage";
import ForgetPasswordPage from "../pages/auth/ForgetPasswordPage";
import OtpForgetPasswordPage from "../pages/auth/OtpForgetPasswordPage";
import OtpPage from "../pages/auth/OtpPage";
import PasswordPage from "../pages/auth/PasswordPage";
import PersonalInfoPage from "../pages/auth/PersonalInfoPage";
import TermsAndConditionsPage from "../pages/auth/TermsAndConditionsPage";
import FiofidPage from "../pages/FiofidPage/FiofidPage";
import HomePage from "../pages/HomePage/HomePage";
import OrderHistoryPage from "../pages/OrderHistoryPage/OrderHistoryPage";
import OrderPage from "../pages/OrderPage/OrderPage";
import PaymentPage from "../pages/PaymentPage/PaymentPage";
import ProfilPage from "../pages/ProfilPage/ProfilPage";
import RestaurantListPage from "../pages/RestaurantListPage/RestaurantListPage";
import RestaurantMenuPage from "../pages/RestaurantMenuPage/RestaurantMenuPage";
import SubMenuPage from "../pages/SubMenuPage/SubMenuPage";
import SuccessPage from "../pages/SuccessPage/SuccessPage";

function AppRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/restaurant/:restaurantId/order/:typeOrder/category/:categoryId/:categoryItemType/:categoryItemId"
          element={<OrderPage />}
        />

        <Route path="/restaurants" element={<RestaurantListPage />} />

        <Route
          path="/restaurant/:restaurantId"
          element={<RestaurantMenuPage />}
        />

        <Route
          path="/restaurant/:restaurantId/:typeOrder/category/:categoryId/:menuId/sub-menus/:menuName"
          element={<SubMenuPage />}
        />
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/" element={<Navigate to="restaurants" replace  />} /> */}

        <Route path="/Authentification" element={<AuthentificationPage />} />

        <Route path="/Authentification/otp" element={<OtpPage />} />

        <Route
          path="/Authentification/personal-info"
          element={<PersonalInfoPage />}
        />

        <Route
          path="/Authentification/passwordPage"
          element={<PasswordPage />}
        />

        <Route
          path="/Authentification/forget-password"
          element={<ForgetPasswordPage />}
        />

        <Route
          path="/Authentification/change-password"
          element={<ChangePasswordPage />}
        />

        <Route
          path="/Authentification/otp-forget-password"
          element={<OtpForgetPasswordPage />}
        />

        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />

        <Route path="/success" element={<SuccessPage />} />

        <Route path="/apropos" element={<AboutPage />} />

        <Route path="/apropos-politique" element={<AboutPage />} />

        <Route path="/mon-profile" element={<ProfilPage />} />

        <Route path="/paiement" element={<PaymentPage />} />

        <Route path="/mes-commandes" element={<OrderHistoryPage />} />
        <Route path="/FIOFID" element={<FiofidPage />} />
      </Routes>
    </HashRouter>
  );
}

export default AppRoutes;
