import { Stack, TextField } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { fromLatLng, setKey } from "react-geocode";
import { useGeolocated } from "react-geolocated";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HomeNavBar from "../../components/HomePage/NavBar/HomeNavBar";
import { useConfig } from "../../context/ConfigContext";
import withNavigateHook from "../../hooks/withNavigateHook";
import btnSearchIcon from "../../images/btn-search-icon.svg";
import {
  clearStoreItem,
  logout,
  setUserMode,
  updateUserLocation,
} from "../../store/actions/actions";
import { getIcon, IconType } from "../../theme/icons";
import "./HomePage.scss";
import { navigateBasedOnRestaurantCount } from "../../utils/navigationUtils";
import Closebtn from "../../images/close-btn.svg";
import Logoappmobile from "../../images/fioeat/Logo-app-mobile.svg";
import { Config } from "../../config";
import bgDesktopImage from "../../images/fioeat/background-desktop-fioeat.png";
import bgMobileImage from "../../images/fioeat/background-mobile-fioeat.png";
import bgTabletImage from "../../images/fioeat/background-tablet-fioeat.png";
import Footer from "../../components/shared/Footer/Footer";

function HomePage(props: any) {
  const { config } = useConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSimple, setIsSimple] = useState<boolean>(false);
  const {
    establishementCount: restaurantCount = 0,
    establishementId: firstRestaurantId = 0,
    showSearchBar = false,
    isGlobalWebsite = false,
  } = config || {};

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [autoComplete, setAutoComplete] = useState<any>();
  const [addressText, setAddressText] = useState<string>(
    props.location?.shownAddress ? props.location?.shownAddress : ""
  );
  const [isBannerVisible, setBannerVisible] = useState(true);
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  let place: any;

  useEffect(() => {
    props.setUserMode(0);
    props.clearItemStore("user");
    props.clearItemStore("authGoogleSuccess");
    props.clearItemStore("authAppleSuccess");
    props.clearItemStore("auth3dthird");
    props.clearItemStore("authError");
  }, []);

  const navigateToRestaurant = () => {
    if (restaurantCount > 1) {
      navigate("/restaurants");
    } else {
      navigate(`/restaurant/${firstRestaurantId}`);
    }
  };

  useEffect(() => {
    setKey(localStorage.getItem("key_google") || "");
  }, [props.token]);

  useEffect(() => {
    // Logic to determine the navbar state based on the current location
    if (
      location.pathname.includes("/Authentification") ||
      location.pathname.includes("/payment")
    ) {
      setIsSimple(true);
      setIsExpanded(false);
    } else {
      if (location.pathname.includes("/restaurant")) {
        setIsSimple(false);
        setIsExpanded(false);
      } else {
        setIsSimple(false);
        setIsExpanded(true);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (props.location?.shownAddress)
      setAddressText(props.location?.shownAddress);
  }, [props.location?.shownAddress]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setIsIOS(true);
    }
    if (/android/.test(userAgent)) {
      setIsAndroid(true);
    }
  }, []);

  useGeolocated({
    onSuccess: (success: GeolocationPosition) => {
      let obj = {
        lat: success?.coords?.latitude,
        lng: success?.coords?.longitude,
      };
      if (
        !props?.location ||
        props?.location?.shownAddress == undefined ||
        props?.location?.shownAddress == ""
      )
        fromLatLng(obj.lat, obj.lng)
          .then((res) => {
            if (res.status == "OK") {
              console.log("UPDATED LOC");
              props.updateUserLocation({
                ...obj,
                shownAddress: res?.results[0]?.formatted_address,
              });
            }
          })
          .catch((error) => console.log("error", error));
    },
  });

  const onPlaceChanged = () => {
    try {
      if (!autoComplete) return;
      console.log("palce not auto ");
      place = autoComplete?.getPlace();

      let obj = {
        lat: place?.geometry?.location.lat(),
        lng: place?.geometry?.location.lng(),
      };

      props.updateUserLocation({
        ...obj,
        shownAddress: place?.formatted_address,
      });
      if (place?.formatted_address != undefined) {
        if (place?.formatted_address == undefined) {
          console.log("place");

          return;
        }
        if (place?.formatted_address) {
          if (props.location?.pathname !== "/restaurants") {
            console.log("place");
            navigateBasedOnRestaurantCount(
              restaurantCount,
              isGlobalWebsite,
              props.navigate
            );

            setAddressText(place?.formatted_address);
          }
        }
      }
    } catch (error) {
      console.log("place", { error });
    }
  };

  //Search locatio Autocomplete states
  const onAutoCompleteLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };

  const runLocation = () => {
    if (props.location?.shownAddress) {
      if (props.location?.pathname !== "/restaurants") {
        navigateBasedOnRestaurantCount(
          restaurantCount,
          isGlobalWebsite,
          props.navigate
        );

        setAddressText(props.location?.shownAddress);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      props.clearItemStore("location");

      setAddressText("");
    }
  };

  const closeBanner = () => {
    setBannerVisible(false);
  };
  console.log("home page - config", config);
  return (
    <div className={`home-page ${isBannerVisible ? "" : "mobile_version"}`}>
      {/* Banner */}
      {config?.isGlobalWebsite && isBannerVisible && (
        <div className="mobile-banner">
          {isIOS && (
            <div className="ios-device">
              <div className="banner-wrapper">
                <div className="content-app">
                  <img
                    className="close-btn"
                    src={Closebtn}
                    alt=""
                    onClick={closeBanner}
                  />
                  <div className="info-block">
                    <img src={Logoappmobile} alt="" />
                    <div>
                      <p>FIOEAT pour IOS</p>
                      <span>Disponible sur AppStore</span>
                    </div>
                  </div>
                </div>
                <button>
                  <a href={Config.iosAppUrl} target="_blank">
                    <p>Installer</p>
                  </a>
                </button>
              </div>
            </div>
          )}

          {isAndroid && (
            <div className="android-device">
              <div className="banner-wrapper">
                <div className="content-app">
                  <img
                    className="close-btn"
                    src={Closebtn}
                    alt=""
                    onClick={closeBanner}
                  />
                  <div className="info-block">
                    <img src={Logoappmobile} alt="" />
                    <div>
                      <p>FIOEAT pour Android</p>
                      <span>Disponible sur Play Store</span>
                    </div>
                  </div>
                </div>
                <button>
                  <a href={Config.androidAppUrl} target="_blank">
                    <p>Installer</p>
                  </a>
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Body */}
      <div className="BodyPage">
        <img
          className="bg_page bg_desktop"
          src={config?.backgroundDesktopImage}
          alt=""
        />
        <img
          className="bg_page bg_tablet"
          src={config?.backgroundTabletImage || config?.backgroundDesktopImage}
          alt=""
        />
        <img
          className="bg_page bg_mobile"
          src={config?.backgroundMobileImage || config?.backgroundDesktopImage}
          alt=""
        />
        <div className="fixed-header-nav">
          <HomeNavBar />
        </div>
        <div className="landing-page-component-content">
          <div className="search-content">
            {/* <img className="title-block" src={titleLandingpage} alt="" /> */}
            <p
              className="title-block"
              style={{
                color: config?.hasDarkBackground
                  ? "#fff"
                  : config?.secondaryColor,
              }}
            >
              Vos restaurants préférés <br /> au bout du doigt.
            </p>
            {showSearchBar || isGlobalWebsite ? (
              <Stack className="input-section-search" direction="column">
                {isExpanded && !isSimple ? (
                  <div className="inputSearch">
                    <Autocomplete
                      restrictions={{ country: "fr" }}
                      onPlaceChanged={() => onPlaceChanged()}
                      onLoad={(autocomplete) =>
                        onAutoCompleteLoad(autocomplete)
                      }
                    >
                      <TextField
                        className="search-engine-restaurant"
                        // size="small"
                        placeholder="Cherchez un restaurant à proximité de vous !"
                        value={addressText}
                        onKeyDown={handleKeyDown}
                        onChange={(evt) => setAddressText(evt.target.value)}
                        InputProps={{
                          startAdornment: (
                            <div className="">
                              {getIcon(IconType.LOCATION_ON_ROUND, {
                                color: config?.primaryColor,
                              })}
                            </div>
                          ),
                        }}
                      />
                    </Autocomplete>
                    <div className="icon-search">
                      <img src={btnSearchIcon} alt="" onClick={runLocation} />
                    </div>
                    {/* <GoogleMaps /> */}

                    {/* <NewGoogleMaps /> */}
                  </div>
                ) : null}
              </Stack>
            ) : (
              <button className="btn-visit" onClick={navigateToRestaurant}>
                Découvrir nos plats ?
              </button>
            )}
          </div>
          {/* <SearchLocInput /> */}
        </div>
      </div>

      {/* Footer */}
      {config?.isGlobalWebsite && (
        <div className="fixed-footer">
          <Footer />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    authError: state.auth.authError,
    savedCategories: state.cart.savedCategories,
    token: state.auth.token,
    authGoogleSuccess: state.auth.authGoogleSuccess,
    authAppleSuccess: state.auth.authAppleSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
    updateUserLocation: (location: any) =>
      dispatch(updateUserLocation(location)),
    setUserMode: (mode: number) => dispatch(setUserMode(mode)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(HomePage));
