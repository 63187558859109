import axios from "axios";
import storage from "redux-persist/lib/storage";


export const authAxios = axios.create({});
export const unAuthAxios = axios.create({});

unAuthAxios.interceptors.request.use(
  (config: any) => {
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

authAxios.interceptors.request.use(
  async (config: any) => {
    const accessToken = await storage.getItem("accessToken");
    //const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI0Y2JlYmM3NC01NTQwLTQyOGMtODA2Zi1jOGRjY2Y0NzJjMmEiLCJuYW1lIjoiU2FnaHJvdW4iLCJyb2xlIjoiIiwibmJmIjoxNzExMzcyMzI2LCJleHAiOjE3MTE5NzcxMjYsImlhdCI6MTcxMTM3MjMyNiwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.feb1bmyHVkaohdv_nmTR2AijcHtB6HhRL4WWNe5kRyc";
    config.headers = {
      ...config.headers,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",

    };

    if (accessToken) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      };
    } else {
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

async function redirectToLogin() {
  try {
    await localStorage.removeItem("accessToken");
    await localStorage.removeItem("token");
    window.location.replace("/");
  } catch (error) {
    console.error(error);
  }
}

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error.", error);

    // Handle token refresh here
    try {
      // redirectToLogin();
      return Promise.reject(error);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }
);

unAuthAxios.interceptors.response = authAxios.interceptors.response;

export const authApi = {

  sendVerificationCode: async (
    body: serviceAuthModels.SendVerificationCodeBody
  ) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    const accessToken = await storage.getItem("accessToken");
    let usedAxios = accessToken ? authAxios : unAuthAxios;
    const response = await usedAxios.post<serviceAuthModels.SendVerificationCodeResponse>(
      baseUrl + "/Account/SendVerificationCode",
      body
    );
    console.log("sendVerificationCode response", response)
    return response
  },

  verifyCode: async (body: serviceAuthModels.verifyCodeBody) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    const accessToken = await storage.getItem("accessToken");
    let usedAxios = accessToken ? authAxios : unAuthAxios;
    return usedAxios.post<serviceAuthModels.verifyCodeResponse>(
      baseUrl + "/Account/VerifyCode",
      body
    );
  },

  verifyGoogleToken: async (token: string) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return unAuthAxios.post(baseUrl + "/Account/verify-google?isWeb=true", {
      tokenId: token,
    });
  },

  verifyAppleToken: async (token: string) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return unAuthAxios.post(baseUrl + "/Account/verify-apple", {
      tokenId: token,
    });
  },

  signup: (body: serviceAuthModels.signupBody) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return unAuthAxios.post<serviceAuthModels.verifyCodeResponse>(
      baseUrl + "/Account/SignUp",
      body
    );
  },

  getmyprofile: () => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.get(baseUrl + "/Account/GetUser");
  },

  editmyprofile: (user: {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
  }) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.post(baseUrl + "/Account/EditUser", { ...user });
  },
};

export const buildUrl = (endpoint: string, params?: Record<string, string>): string => {
  const baseUrl = localStorage.getItem("api_baseUrl");
  const queryString = params
    ? '?' + new URLSearchParams(params).toString()
    : '';
  const url = `${baseUrl}${endpoint}${queryString}`
  console.log("🚀 ~ buildUrl ~ url:", url)
  return url;
};

export const shopApi = {
  getFiltredShop: async (
    body: serviceShopModels.getFiltredShopBody,
    companyId?: string
  ) => {
    const url = buildUrl('/Restaurants/v2', companyId ? { companyId } : undefined);
    return authAxios.post<serviceShopModels.getFiltredShopResponse>(url, body);
  },

  getFavoritesFiltredShop: (
    body: serviceShopModels.getFiltredShopBody,
    companyId?: string
  ) => {
    const url = buildUrl('/Restaurants/v2', companyId ? { companyId } : undefined);
    return authAxios.post<serviceShopModels.getFiltredShopResponse>(url, { ...body, favoris: true });
  },
  getCategoriesList: () => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return unAuthAxios.get<any>(baseUrl + "/Restaurants/list-categories");
  },
  getShopMenu: (body: serviceMenuModels.getMenuBody) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return unAuthAxios.post<serviceMenuModels.getMenuResponse>(
      baseUrl + "/Restaurants/GetByIdIncludeMenu",
      body
    );
  },
  getResturantById: (body: serviceMenuModels.getMenuBody) => {
    console.log("🚀 getResturantById ~ body:", body)
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.post<serviceMenuModels.getMenuResponse>(
      baseUrl + "/Restaurants/v2/GetById",
      body
    );
  },
  addShopToFavorite: (idRestaurant: number, value: boolean) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.post(
      baseUrl +
      "/Restaurants/favorite?idRestaurant=" +
      idRestaurant +
      "&isFavorite=" +
      value
    );
  },
  searchByKey: (key: string, body: serviceShopModels.getFiltredShopBody) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.post<serviceShopModels.getFiltredShopResponse>(
      baseUrl + "/Restaurants",
      { ...body, nom: key }
    );
  },
};

export const orderApi = {
  getOrdersList: () => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.get(baseUrl + "/Commandes");
  },
  getOrderById: (commandId: string) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.get(baseUrl + "/Commandes/V2/CommandeById?id=" + commandId);
  },
};

export const paymentApi = {
  createPayment: (body: servicePaymentModule.createPayment) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.post(baseUrl + "/create", body);
  },
  confirmPayment: (body: servicePaymentModule.confirmpayment) => {
    const baseUrl = localStorage.getItem("api_baseUrl");
    return authAxios.post(baseUrl + "/confirm", body);
  },
};
