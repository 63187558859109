import { Card, CardActions, Link } from "@mui/joy";
import { Stack } from "@mui/material";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { TypeOrder } from "../../../constants/constants";
import withNavigateHook from "../../../hooks/withNavigateHook";
import { languageData } from "../../../i18n";
import { deliveryApi } from "../../../services/deliveryApi";
import { setActiveRestaurant } from "../../../store/actions/actions";
import { setDeliveryAddress } from "../../../store/V2/delivery/deliveryActions";
import "../../../styles/cardsStyle.scss";
import { findCurrentShift } from "../../../utils/shopSubtittleFormat";
import AddressSelectionModal from "../../shared/Modals/DeliveryModal/AddressSelectionModal";
import RestaurantErrorModal from "../../shared/Modals/RestaurantErrorModal/RestaurantErrorModal";
import ShiftInfo from "../../shared/ShiftInfo/ShiftInfo";
import SkeletonElement from "../../shared/Skeletons/SkeletonElement/SkeletonElement";

interface RestaurantItemProps {
  typeOrder: TypeOrder;
  [key: string]: any;
}

function RestaurantItem(props: RestaurantItemProps) {
  const { id, nom, imageUrl, shop, typeOrder } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [curentShop, setCurentShop] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const currentShift = findCurrentShift(props.shop);
  const [showModal, setShowModal] = useState(false);
  const [showRestaurantError, setShowRestaurantError] = useState(false);
  localStorage.setItem("shop", JSON.stringify(curentShop));

  const handleShowErrorModal = () => {
    setShowRestaurantError(true);
  };

  const handleCloseErrorModal = () => {
    setShowRestaurantError(false);
  };

  const handlePressbtnModal = () => {
    setShowRestaurantError(false);
    setShowModal(true);
  };

  const reduceTheName = (nom: string, lengthnum: number) => {
    return nom.split("").length > lengthnum
      ? `${nom.substring(0, lengthnum)}...`
      : nom;
  };

  const handleClick = () => {
    // if (typeOrder === MODE_DELIVERY) {
    //   setShowModal(true);
    // } else {
      props.setActiveRestaurant(shop);
      setCurentShop(shop);
      navigate(`/restaurant/${id}`, { state: { mode: typeOrder } });
    // }
  };

  const preventContextMenu = (e: any) => {
    e.preventDefault();
  };

  const checkRestaurantDelivery = async (
    address: google.maps.places.PlaceResult | undefined
  ) => {
    deliveryApi
      .checkRestaurantDelivery(shop.id, address)
      .then((res) => {
        props.setActiveRestaurant(shop);
        setCurentShop(shop);
        navigate(`/restaurant/${id}`, { state: { mode: typeOrder } });
      })
      .catch((error) => {
        console.log("error", error);
        handleShowErrorModal();
        // props.setActiveRestaurant(shop);
        // setCurentShop(shop);
        // navigate(`/restaurant/${id}`, { state: imageUrl });
      });
  };

  const handleConfirmAddress = (
    address: google.maps.places.PlaceResult | undefined
  ) => {
    // setShowModal(false);
    console.log("Confirmed Address: ", address);
    dispatch(setDeliveryAddress(address));
    checkRestaurantDelivery(address);
  };

  return (
    <div className="card-menu-item">
      <div className="card-container ske">
        {/* <SkeletonElement type="full-block" /> */}
        <Card
          onClick={handleClick}
          variant="plain"
          sx={{
            height: { xs: "165px", md: "fit" },
            boxShadow: "md",
            cursor: "pointer",
            borderRadius: 12,
            overflow: "hidden",
            "--Card-padding": "0px",
            "--AspectRatio-radius": 0,
            "--CardCover-radius": 0,
            position: "relative",
            marginLeft: "-10px",
          }}
          style={{
            width: "350px",
            maxWidth: "100%",
          }}
          className={`cardDosContainer full-size ${props.isOpen ? "Online" : "Offline"}`}
        >
          <CardActions
            buttonFlex="0 1 120px"
            sx={{ position: "absolute", right: 0 }}
          >
            {/* IconButton component here */}
          </CardActions>

          <Link>
            <div className="card-img">
              <p className="status">{props.isOpen ? "" : "Fermé"}</p>
              <img
                src={imageUrl}
                alt=""
                style={{ objectFit: "cover", height: "100%" }}
                onLoad={() => setImageLoaded(true)}
                onContextMenu={preventContextMenu}
              />
            </div>
          </Link>
          <div className="redPartPseudoElement" onClick={handleClick}>
            <Stack className="cardInfo">
              <div className="card-header">
                <span className="status-dot"></span>
                <p className="restaurant-name">{reduceTheName(nom, 25)}</p>
              </div>

              <p className="card-footer">
                <ShiftInfo currentShifts={currentShift} />
                {props.distance} Km • {props.tempsPrep} min{" "}
                {props.specialites && `• ${props.specialites}`}
              </p>
            </Stack>
          </div>
          {!imageLoaded && <SkeletonElement type="img-block" />}
        </Card>
      </div>
      <AddressSelectionModal
        showModal={showModal}
        restaurantId={shop.id}
        hideModal={() => setShowModal(false)}
        onConfirmAddress={handleConfirmAddress}
      />
      <RestaurantErrorModal
        showRestaurantError={showRestaurantError}
        onPressBtn={handlePressbtnModal}
        onCloseModal={handleCloseErrorModal}
        modalTitle={languageData.fr.delivery.deliveryErrorModal.title}
        modalDescription={languageData.fr.delivery.deliveryErrorModal.message}
        modalButtonLabel={
          languageData.fr.delivery.deliveryErrorModal.actionButtonLabel
        }
      />
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setActiveRestaurant: (restaurant: any) =>
      dispatch(setActiveRestaurant(restaurant)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(RestaurantItem));
