import { LoyaltyEstablishment } from "../interfaces/loyality.interface";
import { authAxios, buildUrl } from "./apiGate";

export const loyalityApi = {

  fetchLoyaltyRestaurants: async (
    name: string,
  ) => {
    const params = { name }
    const url = buildUrl('/Account/getLoyalityEstablishementByClient', params);
    return authAxios.get<LoyaltyEstablishment[]>(url);
  }
}
