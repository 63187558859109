import dayjs from "dayjs";

export const selectedTimeRightISO = (timeString: any) => {
  if (!timeString) return null;

  const todayDate = dayjs().format("YYYY-MM-DD");
  const combinedDateTimeString = `${todayDate}T${timeString}`;
  return combinedDateTimeString;
};

export const formatDate = (dateString: string): { formattedDate: string, formattedTime: string } => {
  const date = new Date(dateString);
  const options :any = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('fr-FR', options);
  const formattedTime = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

  return { formattedDate, formattedTime };
};