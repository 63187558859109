import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { IconButton } from "@mui/material";
import { Ingredient } from "../../../../../../interfaces/Catalogue";
import { getPriceByTypeOrderForComposition } from "../../../../../../utils/priceUtils";

interface IngredientQuantityProps {
  ingredient: Ingredient;
  quantity: number;
  typeOrder: number;
  maxSelection: number;
  totalSelectedNotInclus: number;
  compositionMaxSelection: number;
  className?: string;
  onQuantityChange: (newQuantity: number) => void;
}

const IngredientQuantity = (props: IngredientQuantityProps) => {
  const {
    ingredient,
    onQuantityChange,
    maxSelection,
    quantity,
    typeOrder,
    compositionMaxSelection,
    totalSelectedNotInclus,
    className
  } = props;
  
  const priceComposition = getPriceByTypeOrderForComposition(
    typeOrder,
    ingredient
  );

  const increaseQuantity = () => {
    if (quantity < maxSelection) {
      onQuantityChange(quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 0) {
      onQuantityChange(quantity - 1);
    }
  };

  return (
    <div
      className={`multiselectionCounter row ${className ? className : ""}`}
      style={{
        fontSize: "14px",
        fontWeight: "bold",
        alignItems: "center",
        color: "GrayText",
      }}
    >
      <div>
        <span
          style={{
            color: `${props.quantity === maxSelection ? "#C7C8CC" : " "}`,
          }}
        >
          {ingredient?.name}
          <span className="price-el">
            {priceComposition > 0 && ` + ${priceComposition} €`}
          </span>
        </span>
      </div>
      <div className="multiselectionCounterQyt">
        {ingredient.isOutOfStock ? (
          <span className="epuise">Épuisé</span>
        ) : (
          <>
            <span
              style={{
                width: quantity > 0 ? "50px" : "0px",
                overflowX: "hidden",
                transitionDuration: "700ms ",
              }}
              className="row all-center"
            >
              <IconButton
                size="small"
                disabled={quantity === 0}
                onClick={decreaseQuantity}
                style={{ margin: quantity > 0 ? "0 5px" : undefined }}
                className={
                  quantity === 0 || ingredient.isOutOfStock ? "hidden" : ""
                }
              >
                <RemoveCircleOutlineRoundedIcon />
              </IconButton>
              <span className="quantity-number"> {quantity} </span>
            </span>

            <IconButton
              size="small"
              disabled={
                ingredient.isOutOfStock ||
                (compositionMaxSelection === 0 && quantity <= maxSelection
                  ? false
                  : totalSelectedNotInclus >= compositionMaxSelection ||
                    quantity >= maxSelection)
              }
              onClick={increaseQuantity}
            >
              <AddCircleOutlineRoundedIcon fontSize="medium" />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};

export default IngredientQuantity;
