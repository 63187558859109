import React, { useEffect, useState } from "react";
import {
  CategoryItem,
  Ingredient,
  OrderItem,
  SelectedArticle,
  SelectedIngredient,
} from "../../../../interfaces/Catalogue";
import IngredientItem from "../IngredientItem/IngredientItem";

interface IngredientListProps {
  ingredients: Ingredient[];
  compositionMaxSelection: number | null;
  updateSelectedIngredients: (
    selectedIngredients: SelectedIngredient[]
  ) => void;
  typeOrder: number;
  article: CategoryItem;
}

const IngredientList = (props: IngredientListProps) => {
  const {
    article,
    ingredients,
    compositionMaxSelection,
    typeOrder,
    updateSelectedIngredients,
  } = props;

  const [selectedIngredients, setSelectedIngredients] = useState<
    SelectedIngredient[]
  >([]);

  useEffect(() => {
    const initialSelectedIngredients = ingredients
      ?.filter((ingredient: Ingredient) => ingredient.inclus)
      ?.map((ingredient: Ingredient) => ({
        id: ingredient.id,
        ingredient,
        quantity: 1,
      }));

    setSelectedIngredients(initialSelectedIngredients);
  }, [ingredients]);

  const totalSelectedNotInclus = selectedIngredients?.filter(ingredient => !ingredient.ingredient.inclus).reduce(
    (total, item) => total + item.quantity,
    0
  );

  const updateCheckedCompositions = (
    ingredientId: number,
    ingredient: Ingredient,
    quantity: number
  ) => {
    setSelectedIngredients((prev) => {
      const existing = prev?.find((item) => item.id === ingredientId);
      if (existing) {
        if (quantity === 0) {
          return prev?.filter((item) => item.id !== ingredientId);
        }
        return prev?.map((item) =>
          item.id === ingredientId ? { ...item, quantity } : item
        );
      }
      return [...prev, { id: ingredientId, ingredient, quantity }];
    });
  };

  useEffect(() => {
    updateSelectedIngredients(selectedIngredients);
  }, [selectedIngredients]);

  return (
    <>
      {ingredients?.map((ingredient) => (
        <IngredientItem
          key={ingredient.id}
          ingredient={ingredient}
          selectedIngredients={selectedIngredients}
          updateCheckedCompositions={updateCheckedCompositions}
          totalSelectedNotInclus={totalSelectedNotInclus}
          compositionMaxSelection={compositionMaxSelection || 0}
          typeOrder={typeOrder}
          className="spaced-y-8"
        />
      ))}
    </>
  );
};

export default IngredientList;
