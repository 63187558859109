import { TextField } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useConfig } from "../../../context/ConfigContext";
import { languageData } from "../../../i18n";
import btnSearchIcon from "../../../images/btn-search-icon.svg";
import { getIcon, IconType } from "../../../theme/icons";
import "./AdressInput.scss";

interface AdressInputProps {
  onAdressChange: (deliveryAddress: google.maps.places.PlaceResult | undefined) => void;
  showSearchIcon?: boolean;
}

const AdressInput = (props: AdressInputProps) => {
  const { onAdressChange, showSearchIcon = false } = props;
  const { config } = useConfig();
  const deliveryAddress = useSelector(
    (state: any) => state.delivery.deliveryAddress
  );
  const [autoComplete, setAutoComplete] = useState<any>();
  const [addressText, setAddressText] = useState<string>();

  useEffect(() => {
    onAdressChange(deliveryAddress);
    setAddressText(deliveryAddress?.formatted_address);
  }, []);

  const onPlaceChanged = () => {
    try {
      if (!autoComplete) return;
      const place = autoComplete?.getPlace();
      if (!place) {
        console.error("Place is undefined or null");
        return;
      }
      setAddressText(place?.formatted_address);
      onAdressChange(place);
    } catch (error) {
      console.error("onPlaceChanged", { error });
    }
  };

  //Search locatio Autocomplete states
  const onAutoCompleteLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };

  const runLocation = () => {
    console.log("runLocation");
  };

  const handleKeyDown = (event: any) => {
    // if (event.key === "Backspace" || event.key === "Delete") {
    //   setAddressText("");
    // }
  };

  return (
    <div id="AdressInput">
      <div className="inputSearch">
        <Autocomplete
          className="full-width"
          restrictions={{ country: "fr" }}
          onPlaceChanged={() => onPlaceChanged()}
          onLoad={(autocomplete) => onAutoCompleteLoad(autocomplete)}
        >
          <TextField
            className="search-engine-restaurant"
            // size="small"
            placeholder={languageData.fr.delivery.enterAddress}
            value={addressText}
            onKeyDown={handleKeyDown}
            onChange={(evt) => setAddressText(evt.target.value)}
            InputProps={{
              startAdornment: (
                <div className="">
                  {getIcon(IconType.LOCATION_ON_ROUND, {
                    color: config?.primaryColor,
                  })}
                </div>
              ),
            }}
          />
        </Autocomplete>
        {showSearchIcon && (
          <div className="icon-search">
            <img src={btnSearchIcon} alt="" onClick={runLocation} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdressInput;
