import moment from "moment";
import { Link } from "react-router-dom";
import CloseIcon from "../../images/CloseIcon_btn.svg";
import restuarntNameExample from "../../images/restuarntNameExample.png";
import WishListIcon from "../../images/WishListIcon.svg";
import { RestaurantCart } from "../../interfaces/Catalogue";
import CommandDetails from "../shared/CommandDetails/CommandDetails";
import { formatDate } from "../../utils/dateUtils";
import { getDiningModeLabel, MODE_DELIVERY } from "../../constants/constants";

export default function InvoiceCommand({
  command,
  qrcode,
  clearItemStore,
  restaurantCart,
  PrepareFor,
}: {
  command?: any;
  qrcode: string;
  clearItemStore: () => void;
  restaurantCart?: RestaurantCart;
  PrepareFor: string | null;
}) {
  const parseDetailCommande = JSON.parse(command?.detailCommande || "{}");
  const { formattedDate, formattedTime } = formatDate(PrepareFor || "");

  const convertHexToByteArray = (hexString: string) => {
    const byteCharacters = atob(hexString || "");
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  };

  let byteArray = convertHexToByteArray(qrcode);
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  const imageUrl = URL.createObjectURL(blob);

  return (
    <div className="InvoiceCommand">
      <div className="InvoiceContainer">
        <Link
          className="btn-cmd"
          to="/mes-commandes"
          onClick={() => clearItemStore()}
        >
          <img src={CloseIcon} alt="" />
        </Link>
        <div className="invoice-header">
          <img className="restaurant-img" src={restuarntNameExample} alt="" />
          <p className="restaurant-name">{command?.nomRestaurant}</p>
          <img src={WishListIcon} alt="" className="wishList" />
        </div>
        <div className="invoice-body">
          <div>
            {command?.status === "cashPayment" ? (
              <p className="message">
                Dirigez-vous vers la caisse pour le règlement.
              </p>
            ) : command?.diningMode !== MODE_DELIVERY ? (
              <p className="message">
                Veuillez récupérer votre bipeur à la caisse.
              </p>
            ) : null}
          </div>

          <div className="QrCode">
            <p>Votre ticket</p>
            {command?.status === "cashPayment" ? (
              <img className="qrCodeImg" src={imageUrl} alt="" />
            ) : null}

            {command && (
              <div className="Commandinfo">
                <p className="command-number">
                  Commande N° {command?.referenceCommande}
                </p>
                {/* <p className="command-time">A emporter : entre 19:30 et 20:00 </p> */}
              </div>
            )}
          </div>

          {command && (
            <div className="Ticket-details">
              <div className="ticket-header">
                {/* <p className="title-ticket">Votre ticket</p> */}
                <div>
                  <p className="time-ticket">
                    Aujourd’hui à {moment().format("HH:mm")}
                  </p>
                  <p className="delivery-ticket">
                    {getDiningModeLabel(command.diningMode)}
                    {PrepareFor &&
                      `, prêt à ${formattedTime} le ${formattedDate}`}
                  </p>
                </div>
              </div>

              <div style={{ paddingBottom: 20 }}>
                {parseDetailCommande &&
                  parseDetailCommande?.map((categoryItem: any) => (
                    <div>
                      <div className="menu-header">
                        <div className="col-1-header">
                          <p className="menu-name">
                            {categoryItem?.quantity +
                              " " +
                              categoryItem?.designation}
                          </p>
                          <img
                            className="menu-img"
                            src={categoryItem?.imgUrl || ""}
                            alt={categoryItem?.designation}
                          />
                        </div>
                        <div className="col-2-header">
                          <div className="price-container">
                            <p className="price">
                              {(
                                (categoryItem?.finalUnitPrice ?? 0) *
                                (categoryItem?.quantity ?? 0)
                              ).toFixed(2)}{" "}
                              €
                            </p>
                          </div>
                        </div>
                      </div>
                      <CommandDetails
                        categoryItem={categoryItem}
                        mode={restaurantCart?.mode || 0}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        {command && (
          <div className="invoice-footer">
            <div className="subtotal">
              <p>Sous-total</p>
              <p>{command?.totalPrix?.toFixed(2)}€</p>
            </div>
            <div className="expenses">
              <p>Frais</p>
              <p>{command?.deliveryFee?.toFixed(2) || "0.00"}€</p>
            </div>
            <div className="total">
              <p>Total</p>
              <p>{(command?.totalPrix + command?.deliveryFee)?.toFixed(2)}€</p>
            </div>
          </div>
        )}

        {command?.diningMode == MODE_DELIVERY && command?.deliveryUrl && (
          <div className="footer-details">
            <button
              onClick={() => window.open(command?.deliveryUrl, "_blank")}
              className="btn-track"
            >
              Suivre votre commande
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
