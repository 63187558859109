import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfig } from "../../../../../context/ConfigContext";
import {
  Cart,
  CategoryItem,
  RestaurantCart,
} from "../../../../../interfaces/Catalogue";
import { updateOrder } from "../../../../../store/actions/actions";
import { getIcon, IconType } from "../../../../../theme/icons";
import CommandDetails from "../../../CommandDetails/CommandDetails";

interface CartSubElementsProps {
  categoryItem: CategoryItem;
  index: number;
  restaurantId: number;
}

const CartSubElements = (props: CartSubElementsProps) => {
  const { config } = useConfig();
  const { categoryItem, index, restaurantId } = props;
  const dispatch = useDispatch();
  const [total, setTotal] = useState(categoryItem.finalUnitPrice || "0.00");
  const savedCategories = useSelector(
    (state: { cart: Cart }) => state.cart.savedCategories
  );
  const restaurantCart: RestaurantCart = savedCategories[restaurantId];

  useEffect(() => {
      const calculatedTotal = (
        (categoryItem?.finalUnitPrice ?? 0) * (categoryItem?.quantity ?? 0)
      ).toFixed(2);

      setTotal(calculatedTotal);
  }, [categoryItem.quantity]);

  const __updateOrder = (actionType: "INCREASE" | "DECREASE") => {
    let curreSavedCart = savedCategories;
    let category: CategoryItem =
      savedCategories[restaurantId]?.categories[index];

    switch (actionType) {
      case "INCREASE":
        category.quantity = (category?.quantity || 0) + 1;
        curreSavedCart[restaurantId].categories[index] = category;
        break;

      case "DECREASE":
        if (category?.quantity && category?.quantity > 1) {
          category.quantity = (category?.quantity ?? 0) - 1;
          curreSavedCart[restaurantId].categories[index] = category;
        } else {
          if ((category?.quantity ?? 0) - 1 <= 0) {
            let cats = curreSavedCart[restaurantId].categories;
            cats?.splice(index, 1);
            curreSavedCart[restaurantId].categories = cats;

            let selectedCategories = curreSavedCart[restaurantId].categories;
            if (selectedCategories?.length === 0) {
              delete curreSavedCart[restaurantId];
            }
          }
        }
        break;

      default:
        throw new Error(`Unsupported action type: ${actionType}`);
    }

    dispatch(updateOrder(curreSavedCart));
  };

  return (
    <div className="menuElement animated animatedFadeInUp fadeInUp">
      <div className="menu-header">
        <div className="col-1-header">
          <p className="menu-name">{categoryItem?.designation}</p>
          <img
            className="menu-img"
            src={categoryItem?.imgUrl || ""}
            alt={categoryItem?.designation}
          />
        </div>
        <div className="col-2-header">
          <div className="slot-menu">
            {categoryItem?.quantity === 1
              ? getIcon(IconType.TRASH, {
                  color: config?.primaryColor,
                  onClick: () => __updateOrder("DECREASE"),
                })
              : getIcon(IconType.MINUS, {
                  color: config?.primaryColor,
                  onClick: () => __updateOrder("DECREASE"),
                })}
            <p className="quantity">{categoryItem?.quantity}</p>
            {getIcon(IconType.PLUS, {
              color: config?.primaryColor,
              onClick: () => __updateOrder("INCREASE"),
            })}
          </div>
          <div className="price-container">
            <p className="price">{total} €</p>
          </div>
        </div>
      </div>

      <CommandDetails categoryItem={categoryItem} mode={restaurantCart.mode} />
    </div>
  );
};

export default CartSubElements;
