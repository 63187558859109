import React, { ReactNode, useState } from "react";
import RightArrowGrey from "../../images/RightArrowGrey.svg";
import LeftArrowCircleGrey from "../../images/LeftArrowCircleGrey.svg";
import ActivitieCard from "./ActivitieCard";
import { loyaltyActivitiesData, LoyaltyActivity } from "../../interfaces/loyality.interface";
/**
 * CardFidItem component description.
 *
 * @CardFidItem
 *
 */

interface CardFidItemProps {
  restaurantName: string;
  restaurantImg: string;
  points: number;
}

const CardFidItem: React.FC<CardFidItemProps> = ({
  points,
  restaurantName,
  restaurantImg,
}) => {
  const [showActivityCard, setShowActivityCard] = useState(false);
  const [loyaltyActivities, setLoyaltyActivities] = useState<LoyaltyActivity[]>(loyaltyActivitiesData);

  const HandelActivityCard = () => {
    setShowActivityCard((prevState) => !prevState);
  };

  return (
    <div
      className={`card-item-section ${showActivityCard ? "showActivityCard" : ""}`}
    >
      <div className="card-item">
        <div className="card-wrapper">
          {showActivityCard && (
            <img
              width={"40px"}
              src={LeftArrowCircleGrey}
              alt=""
              onClick={HandelActivityCard}
            />
          )}

          <div className="card-header">
            <img src={restaurantImg} alt="" className="restaurant-img" />
            <p className="restaurant-name">{restaurantName}</p>
          </div>
          <div className="card-footer fl_row jc_s">
            <p className="solde-txt">
              Solde FID : <span>{points} Points</span>{" "}
            </p>
            <div className="details-btn fl_row" onClick={HandelActivityCard}>
              {/* <p>Détails</p>
              <img width={"25px"} src={RightArrowGrey} alt="" /> */}
            </div>
          </div>
        </div>
        {showActivityCard && (
          <div className=" fl_col">
            <p className="label-list">Activités FID</p>
            <div className="ActivitieCardsList fl_col">
              {loyaltyActivities.map((loyaltyActivity) => (
                <ActivitieCard
                  commandNumber={loyaltyActivity.commandNumber}
                  commandDate={loyaltyActivity.commandDate}
                  points={loyaltyActivity.points}
                  earned={loyaltyActivity.earned}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardFidItem;
