
export const generateSrcSet = (imgUrl: string) => {
    if (!imgUrl) return '';

    const url = imgUrl.replace(/ /g, "%20"); // Ensure spaces are encoded

    return `${url}?w=600&h=400&fit=crop&format=webp 600w,
            ${url}?w=1200&h=800&fit=crop&format=webp 1200w,
            ${url}?w=1800&h=1200&fit=crop&format=webp 1800w,
            ${url}?w=600&h=400&fit=crop 600w,
            ${url}?w=1200&h=800&fit=crop 1200w,
            ${url}?w=1800&h=1200&fit=crop 1800w`;
};

export const convertHexToByteArray = (hexString: string) => {
    const byteCharacters = atob(hexString || '');
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
};