import parsePhoneNumberFromString from "libphonenumber-js";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import Eye from "../../assets/auth-icons/eye.svg";
import { NavAuth } from "../../components/Auth/NavAuth/NavAuth";
import withNavigateHook from "../../hooks/withNavigateHook";
import { languageData } from "../../i18n";
import {
  clearStoreItem,
  sendVerificationCode,
  updateLoading,
  updateTokens,
  updateUserAuth,
  verifyCode,
} from "../../store/actions/actions";
import { validation } from "../../utils/Validation";
import "./AuthStyles.scss";
import { navigateBasedOnRestaurantCount } from "../../utils/navigationUtils";
import { useConfig } from "../../context/ConfigContext";

interface phoneEmailInterface {
  filedInput: string;
  inputType: string | "email" | "tel";
  countryPrefix: string;
}

const PasswordPage = (props: any) => {
  const { config } = useConfig();
  const { establishementCount: restaurantCount = 0, isGlobalWebsite = false } =
    config || {};

  const phoneEmailState: phoneEmailInterface = {
    filedInput: "",
    inputType: "email",
    countryPrefix: "",
  };
  const [password, setPassword] = useState("");
  const [hasErrorServer, sethasErrorServer] = useState("");
  const [retries, setretries] = useState(0);
  const [ShowPw, SetPassword] = useState(false);

  const ValidateInput = () => {
    const phoneNumber = parsePhoneNumberFromString(
      "+" + phoneEmailState.countryPrefix + phoneEmailState.filedInput
    );
    const isMailValid = validation.ValidateEmail(phoneEmailState.filedInput);
    const isPhone =
      phoneNumber?.isValid() &&
      validation.ValidatePhonenumber(phoneEmailState.filedInput);

    return {
      isMailValid,
      isPhone,
    };
  };

  window.addEventListener("popstate", (event) => {
    props.navigate("/");
  });

  useEffect(() => {
    if (props.user === null || props.user.length === 0) {
      props.navigate("/Authentification");
    }
  }, []);

  const sendVerifCode = () => {
    props.sendVerificationCode(
      {
        input: props?.user?.email ? props.user?.email : props?.user?.phone,
        hashCode: "0000",
        preferredMethod: props?.user?.email ? 0 : 1,
        mode: 1,
        password: password,
      },
      false
    );
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      sethasErrorServer("");
      if (!password) {
        sethasErrorServer(
          languageData.fr.auth["login.header.pass.obligatoire"]
        );
      } else {
        sethasErrorServer("");
        sendVerifCode();
        setretries(retries + 1);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  const handleNavigateToFogetPassword = () => {
    props.navigate("/Authentification/forget-password");
  };
  useEffect(() => {
    const redirectedToAuth =
      localStorage.getItem("redirectedToAuth") === "true";
    if (props.sendVerificationCodeResponse) {
      const { token } = props.sendVerificationCodeResponse; // Assuming the token is stored in the 'token' field of the response

      if (token) {
        // Store the token in local storage
        localStorage.setItem("token", token);
      }
      if (password) {
        props.clearItemStore("sendVerificationCodeResponse");
        props.clearItemStore("resendVerificationCodeResponse");
        props.clearItemStore("authError");
        if (props.userRedirectAfterLoginTo) {
          // props.navigation.reset({
          //   index: 0,
          //   routes: [{ name: "TabRouteName" }, { name: props.userRedirectAfterLoginTo }]
          // })

          props.clearItemStore("userRedirectAfterLoginTo");
        } else if (
          props.location?.shownAddress &&
          props.location?.shownAddress !== ""
        ) {
          navigateBasedOnRestaurantCount(
            restaurantCount,
            isGlobalWebsite,
            props.navigate
          );
        } else {
          props.navigate("/");
        }
      }
      if (redirectedToAuth) {
        props.navigate("/paiement");
        return;
      }
    }
  }, [props.sendVerificationCodeResponse, retries]);

  useEffect(() => {
    if (props.authError && !props.sendVerificationCodeResponse) {
      if (props.authError == "Password incorrect") {
        sethasErrorServer(languageData.fr.serverError["pawd.incorrect"]);
      }
    }
  }, [props.authError, props.authRetries]);

  return (
    <div className="authPage">
      <NavAuth />
      <div className="content pw_page">
        <p className="tite-form">Connexion</p>
        <form onSubmit={handleSubmit}>
          <div className="inp-col ">
            <label>Mot de passe *</label>
            <div className="pw-field">
              <input
                className="inp-underline"
                type={`${ShowPw ? "text" : "password"}`}
                value={password}
                onChange={handlePasswordChange}
              />
              {/* <img {()=>ShowPw(!SetPassword)} className="show-pw" src={Eye} alt="" /> */}
              <img
                onClick={() => SetPassword(!ShowPw)}
                className="show-pw"
                src={Eye}
                alt=""
              />
            </div>
          </div>
          <p className="error-msg">{hasErrorServer}</p>
          <p className="forgotPw" onClick={handleNavigateToFogetPassword}>
            Mot de passe oublié?
          </p>

          <button className="btn-form continue" type="submit">
            Continuer
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    location: state.auth.location,
    resendVerificationCodeResponse: state.auth.resendVerificationCodeResponse,
    verifyCodeSuccess: state.auth.verifyCodeSuccess,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    authRetries: state.auth.authRetries,
    authError: state.auth.authError,
    validationStepToken: state.auth.validationStepToken,
    validationSignupToken: state.auth.validationSignupToken,
    userRedirectAfterLoginTo: state.auth.userRedirectAfterLoginTo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserInfo: (userInfo: any) => dispatch(updateUserAuth(userInfo)),
    sendVerificationCode: (
      body: serviceAuthModels.SendVerificationCodeBody,
      resend: boolean
    ) => dispatch(sendVerificationCode(body, resend)),
    updateLoading: (value: boolean) => dispatch(updateLoading(value)),
    verifyCode: (body: serviceAuthModels.verifyCodeBody) =>
      dispatch(verifyCode(body)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    updateTokens: (verifToken: string, signupToken: string, token: any) =>
      dispatch(updateTokens(verifToken, signupToken, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(PasswordPage));
