import { useState } from "react";
import { connect } from "react-redux";
import { useConfig } from "../../../../context/ConfigContext";
import withNavigateHook from "../../../../hooks/withNavigateHook";
import MyprofileIcon from "../../../../images/MyProfile.svg";
import MesCommandesIcon from "../../../../images/mes-commandes.svg";
import FIOFIDIcon from "../../../../images/fidelityIcon.svg";
import { clearStoreItem, logout } from "../../../../store/actions/actions";
import { getIcon, IconType } from "../../../../theme/icons";
import "./ProfilModal.scss";

const Profile = (props: any) => {
  const [show, setShowUserBox] = useState(false);
  const { config } = useConfig();

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    props.clearItemStore("auth3dthird");
    props.clearItemStore("authGoogleSuccess");
    props.clearItemStore("authAppleSuccess");
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }

    props.logout();
    if (props.setLogoutRetries !== undefined) {
      props.setLogoutRetries(Math.random() * 26);
    }
    props.navigate("/");
  };
  const handleGoToProfile = () => {
    props.navigate("/mon-profile");
  };
  const handleGoToMesCommandes = () => {
    props.navigate("/mes-commandes");
  };
  const handleGoToFIOFID = () => {
    props.navigate("/FIOFID");
  };
  return (
    <div className="user-profile">
      {getIcon(IconType.USER, {
        color: config?.primaryColor,
        onClick: () => setShowUserBox(!show),
        className: "toggle-btn-profile",
      })}
      <div className={`user-list-box ${show ? "showBox" : ""}`}>
        <div
          onClick={() => setShowUserBox(!show)}
          className="overlay-box"
        ></div>
        <div className="User-menu">
          <ul>
            <li onClick={handleGoToProfile}>
              {" "}
              <img src={MyprofileIcon} alt="" />
              <p>Mon profil</p>
            </li>
            <span className="diver"></span>
            <li onClick={handleGoToMesCommandes}>
              {" "}
              <img src={MesCommandesIcon} alt="" />
              <p>Mes commandes</p>
            </li>
            <span className="diver"></span>

            <li onClick={handleGoToFIOFID}>
              {" "}
              <img src={FIOFIDIcon} alt="" />
              <p>FIOFID</p>
            </li>
            <span className="diver"></span>
          </ul>
          <p className="logout" onClick={logoutUser}>
            Sign Out
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    savedCategories: state.cart.savedCategories,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    token: state.auth.token,
    authGoogleSuccess: state.auth.authGoogleSuccess,
    authAppleSuccess: state.auth.authAppleSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(Profile));
